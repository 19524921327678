<template>
<div>
  <Particles
      id="tsparticles"
      style="position: absolute, width: 100%,"
      :options="{
          background: {
              color: {
                  value: 'transparent',
              }
          },
          fpsLimit: 30,
          interactivity: {
              detectsOn: 'canvas',
              events: {
                  onClick: {
                      enable: true,
                      mode: 'push'
                  },
                  onHover: {
                      enable: false,
                      mode: 'repulse'
                  },
                  resize: true
              },
              modes: {
                  bubble: {
                      distance: 600,
                      duration: 10,
                      opacity: 0.8,
                      size:32
                  },
                  push: {
                      quantity: 1
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4
                  }
              }
          },
          particles: {
              color: {
                  value: '#000'
              },
              links: {
                  color: '#444',
                  distance: 150,
                  enable: true,
                  opacity: .3,
                  width: .5
              },
              collisions: {
                  enable: false
              },
              move: {
                  direction: 'none',
                  enable: true,
                  outMode: 'none',
                  random: false,
                  speed: .2,
                  straight: true
              },
              number: {
                  density: {
                      enable: true,
                      value_area: 1200
                  },
                  value: 64
              },
              opacity: {
                  value: 0.25
              },
              shape: {
                  type: 'polygon',
                  options: {
                    polygon: [
                    {
                      sides: 3
                    }
                    ]
                  },
              },
              size: {
                  random: true,
                  value: 5
              }
          },
          detectRetina: false
      }"
    />

  <!-- Overlay until the Authorization -->
  <v-overlay :value="overlay" opacity=".98">
    <v-container>
      <v-row justify="center" >
        <h1 class="crisp">Please Wait</h1>
      </v-row>
      <v-row justify="center" class="mb-10">
        <h3 class="crisp">while we are connecting with our services</h3>
      </v-row>
      <v-row justify="center" class="mb-10">
        <v-progress-circular
          indeterminate
          width="1"
          size="64"
        ></v-progress-circular>
      </v-row>
      <v-row justify="center">
        <!-- <v-btn
          class="white--text btGradient"
          @click="overlay = !overlay"
        >
          Expedite Authentication
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </v-btn> -->
      </v-row>
    </v-container>
  </v-overlay>
  
  <v-container class="boxColor styledContainer mt-15" >
    <v-row >
      <v-col class="text-center" cols="12">
        <!-- Logo -->
        <!-- <img src="../assets/logo.png" class="my-5 mb-0 logo" height="128" /> -->
      </v-col>

      <!-- MAIN BODY -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        offset="0"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
        style="min-width: 280px"
      >
      <v-list class="boxColor styledContainer" style="min-width: inherit">

      <v-list-item class="content-center">
        <a v-if="getRegisteredUserInfo.linkedInHandle != '-' && getRegisteredUserInfo.linkedInHandle != false" :href="getRegisteredUserInfo.linkedInHandle"><v-icon large>mdi-linkedin</v-icon></a>
        <a v-if="getRegisteredUserInfo.facebookHandle != '-' && getRegisteredUserInfo.facebookHandle != false" :href="getRegisteredUserInfo.facebookHandle"><v-icon large>mdi-facebook</v-icon></a>
        <a v-if="getRegisteredUserInfo.twitterHandle != '-' && getRegisteredUserInfo.twitterHandle != false" :href="getRegisteredUserInfo.twitterHandle"><v-icon large>mdi-twitter</v-icon></a> <br>
        <a v-if="getRegisteredUserInfo.instagramHandle != '-' && getRegisteredUserInfo.instagramHandle != false" :href="getRegisteredUserInfo.instagramHandle"><v-icon large>mdi-instagram</v-icon></a>
        <a v-if="getRegisteredUserInfo.youtubeHandle != '-' && getRegisteredUserInfo.youtubeHandle != false" :href="getRegisteredUserInfo.youtubeHandle"><v-icon large>mdi-youtube</v-icon></a>
        <a v-if="getRegisteredUserInfo.tiktokHandle != '-' && getRegisteredUserInfo.tiktokHandle != false" :href="getRegisteredUserInfo.tiktokHandle"><img width="32px" src="../assets/tiktok.png"></a>
      </v-list-item>
      <!-- Full Name No -->
      <v-list-item>

        <v-list-item-content>
          <v-list-item-title >
            <!-- Loading skeleton -->
            <!-- <v-skeleton-loader
              v-bind="attrs"
              style="background-color: transparent"
              v-if="getRegisteredUserInfo.displayName"
              type="list-item"
            ></v-skeleton-loader> -->
            <h3>{{getRegisteredUserInfo.displayName}}</h3>
          </v-list-item-title>
          <v-list-item-subtitle ><h4>{{getRegisteredUserInfo.designation}}</h4> <br> <h4 style="font-weight: bold">{{getRegisteredUserInfo.companyName}}</h4></v-list-item-subtitle>
          <v-btn
            color="primary"
            class="m-10"
            max-width="200"
            @click="GenerateVCFFile"
          >
            <h5>Add to Contact</h5>
            <v-icon
              right
              dark
            >
              mdi-contacts
            </v-icon>
          </v-btn>
          
        </v-list-item-content>

        <v-list-item-icon>
            
          </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Action buttons -->
      <!-- <v-list-item three-line>

        <v-list-item-content>
          <div class="text-center"> -->
            
            <!-- <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
            >
              <v-icon dark>
                mdi-phone
              </v-icon>
            </v-btn> -->
          <!-- </div>
        </v-list-item-content>

      </v-list-item> -->
      <!-- Mobile No -->
      <v-list-item v-if="CheckData(getRegisteredUserInfo.mobileNo)">
        <v-list-item-icon>
          <v-icon color="blue">
           mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-if="getRegisteredUserInfo.mobileNo != '-'" v-text="getRegisteredUserInfo.mobileNo"></v-list-item-title>
          <v-list-item-subtitle>Primary Mobile</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <a :href="'sms:' + getRegisteredUserInfo.mobileNo"><v-icon>mdi-message-text</v-icon></a>
          <a :href="'tel:' + getRegisteredUserInfo.mobileNo"><v-icon>mdi-phone</v-icon></a>
        </v-list-item-icon>
      </v-list-item>
      <!-- Secondary Mobile No -->
      <v-list-item v-if="CheckData(getRegisteredUserInfo.altMobileNo)">
        <v-list-item-icon>
          <v-icon color="blue">
            mdi-phone
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.altMobileNo"></v-list-item-title>
          <v-list-item-subtitle>Alternative Mobile </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <a :href="'sms:' + getRegisteredUserInfo.altMobileNo"><v-icon>mdi-message-text</v-icon></a>
          <a :href="'tel:' + getRegisteredUserInfo.altMobileNo"><v-icon>mdi-phone</v-icon></a>
        </v-list-item-icon>
      </v-list-item>
      <!-- Work No -->
      <v-list-item v-if="CheckData(getRegisteredUserInfo.workNo)">
        <v-list-item-icon>
          <v-icon color="blue">
            mdi-phone-classic
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.workNo"></v-list-item-title>
          <v-list-item-subtitle>Work Phone Number</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <a :href="'tel:' + getRegisteredUserInfo.workNo"><v-icon>mdi-phone-classic</v-icon></a>
        </v-list-item-icon>
      </v-list-item>

      <!-- Secondary Work No -->
      <v-list-item v-if="CheckData(getRegisteredUserInfo.secWorkNo)">
        <v-list-item-icon>
          <v-icon color="blue">
            mdi-phone-classic
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.secWorkNo"></v-list-item-title>
          <v-list-item-subtitle>Secondary Work Phone Number</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <a :href="'tel:' + getRegisteredUserInfo.secWorkNo"><v-icon>mdi-phone-classic</v-icon></a>
        </v-list-item-icon>
      </v-list-item>

      <v-divider v-if="CheckData(getRegisteredUserInfo.fax)"></v-divider>

      <!-- Fax No -->
      <v-list-item v-if="CheckData(getRegisteredUserInfo.fax)">
        <v-list-item-icon>
          <v-icon color="blue">mdi-fax</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.fax"></v-list-item-title>
          <v-list-item-subtitle>Fax Work</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
        </v-list-item-icon>

      </v-list-item>

      <v-divider></v-divider>
      <!-- Email Personal -->
      <v-list-item v-if="CheckData(getRegisteredUserInfo.email)"> 
        <v-list-item-icon>
          <v-icon color="blue">
            mdi-email
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.email"></v-list-item-title>
          <v-list-item-subtitle>Personal</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <a :href="'mailto:' + getRegisteredUserInfo.email"><v-icon>mdi-email-fast</v-icon></a>
        </v-list-item-icon>
      </v-list-item>
      <!-- Email Official -->
      <v-list-item v-if="CheckData(getRegisteredUserInfo.emailOfficial)">
        <v-list-item-icon>
          <v-icon color="blue">
            mdi-office-building
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.emailOfficial"></v-list-item-title>
          <v-list-item-subtitle>Work</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <a :href="'mailto:' + getRegisteredUserInfo.emailOfficial"><v-icon>mdi-email-fast</v-icon></a>
        </v-list-item-icon>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item v-if="CheckData(getRegisteredUserInfo.address)">
        <v-list-item-icon>
          <v-icon color="blue">
            mdi-map-marker
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.address">1400 Main Street</v-list-item-title>
          <v-list-item-subtitle v-text="getRegisteredUserInfo.city + ', ' + getRegisteredUserInfo.country">Orlando, FL 79938</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>mdi-map</v-icon>
        </v-list-item-icon>

      </v-list-item>
      <!-- Website -->
      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="blue">
            mdi-web
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="getRegisteredUserInfo.website"></v-list-item-title>
          
        </v-list-item-content>

        <v-list-item-icon>
          <a :href="'https://' + getRegisteredUserInfo.website"><v-icon>mdi-vector-link</v-icon></a>
        </v-list-item-icon>
      </v-list-item>
      </v-list>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        offset="0"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
        class="m-20"
        hidden
      >
      <!-- QR Generator card -->
      <v-list class="boxColor" >
        <!-- Heading -->
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title ><h2>QR Code</h2></v-list-item-title>
            <div class="text-overline">
              <a :href="downloadURL" download>
                <vue-qr
                  id="baseQR"
                  elevation="2"
                  :size="256"
                  :text="qrURL"
                  :logoSrc="src"
                  :dotScale=".75"
                  colorDark="rgb(0, 71, 171)"
                  backgroundDimming="rgb(220,220,220)"
                  :gifBgSrc="gifBgSrc2"
                ></vue-qr>
              </a>
            </div>
            <a :href="downloadURL" download>
                <v-btn
                  color="gray"
                  text
                  @click="DownloadQR()"
                >
                  Download QR
                </v-btn>
              </a>
            <!-- <v-list-item-subtitle>QR is suppose to be visible inside the Canvas, After the profile is loaded</v-list-item-subtitle> -->
          </v-list-item-content>

        </v-list-item>


      </v-list>
      </v-col>

      <!-- guide text -->
      <v-col
        class="mb-5 text-center"
        cols="12"
      >
        <a href="https://absaco.com"><v-btn class="m-20">Visit www.ABSACO.com</v-btn></a>
        
        <h3 class="headline font-weight-bold mb-1">
          <!-- How it works? -->
        </h3>

        <p>
          <!-- Please enter User ID to search profile detials and generate QR Code, QR is suppose to be visible inside the Canvas<br>
          in case of any issue please contact on <a href="mailto:abc@absaco.com" >abc@absaco.com</a> <br> -->
          <span style="color: #999; font-weight: bolder"> 
          App Version
          {{ getAppInfo.version }}
          </span>
        </p>



      </v-col>
      
    </v-row>
  </v-container>


</div>
  
</template>

<script>
import VueQr from 'vue-qr'
import src from "../assets/logo.png";
import gifBgSrc2 from "../assets/map.gif";




export default {
  name: 'Home',

  data: () => ({
  valid: false,
  src,
  gifBgSrc2,
  SearchUserID: '',
  SearchedUser: {},
  attrs: {
    class: 'mb-6',
    boilerplate: false,
    elevation: 0,
  },

  // Overlay
  overlay: true,

  qrURL: '',
  VCFQRupdate: false,
  downloadURL: '',

  }),
  methods: {
    FetchUserInfo() {
      //Sending the instructions to the state
      this.overlay = true
      setTimeout(() => {
      this.$store.dispatch('GetUserRegistrationData', this.SearchUserID).then(() => {
        this.SearchedUser = this.getRegisteredUserInfo
        this.UpdateQR();
        this.overlay = false
      });
    },500)
    },
    CheckUser() {
      // Checking the User
      if (this.GetURLParameter("user") == undefined)
      {
        this.OpenDialog({active: true, heading: "Incorrect Setup Error", description: "Please try to Scap the QR Again" });
      }
    },
    RefreshUserInfo() {
      //Getting the getters
      this.trackingInfo = this.$store.getters.containerInfo
    },
    UpdateQR() {
      //Getting the getters
      this.qrURL = this.getAppInfo.domain + '/search?user=' + this.getRegisteredUserInfo.userID;
      this.downloadURL = document.getElementById('baseQR').src;

      this.VCFQRupdate ? false : true ;
    },
    DownloadQR() {
      //Getting the DOM
      let image = document.getElementById('baseQR');
      console.log("image value", image)
      // let filename = "QR.png";
    },
    GetURLParameter(input) {
      // Getting the information from the URL
      // let URLparameter = this.$route;
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let parsedParams = urlParams.get(input)
      
      return parsedParams;

    },
    GenerateVCFFile() {
      // Pre-requisites
      const FileSaver = require('file-saver');
      let vCardsJS = require('vcards-js');
      // Adding the information to the vcf file
      // VCard
      let vCard = vCardsJS();

      //List Cleanup
      let vCardData = {}
      for(var entry in this.getRegisteredUserInfo)
      {
        if (this.getRegisteredUserInfo[entry] == "-") {
          // console.log("Error", this.getRegisteredUserInfo[entry])
        }else if(this.getRegisteredUserInfo[entry] != undefined || this.getRegisteredUserInfo[entry] != null ){
          vCardData[entry] = this.getRegisteredUserInfo[entry];
          // console.log("Entry", this.getRegisteredUserInfo[entry])
        }
      }

      //set properties
      vCard.firstName = vCardData.displayName;
      //vCard.lastName = vCardData.lastName;
      vCard.organization = vCardData.companyName;
      vCard.role = vCardData.designation;
      vCard.workPhone = [vCardData.workNo, vCardData.secWorkNo];
      vCard.cellPhone = [vCardData.mobileNo, vCardData.altMobileNo];
      //vCard.homePhone = vCardData.altMobileNo;
      vCard.workFax = vCardData.fax;
      vCard.email = vCardData.email;
      vCard.workEmail = vCardData.emailOfficial;
      vCard.title = vCardData.designation;
      vCard.homeAddress.street = vCardData.address;
      vCard.homeAddress.city = vCardData.city;
      vCard.homeAddress.countryRegion = vCardData.country;
      // vCard.URL['facebook'] = vCardData.facebookHandle;
      vCard.socialUrls['facebook'] = vCardData.facebookHandle;
      vCard.socialUrls['linkedIn'] = vCardData.linkedInHandle;
      vCard.socialUrls['instagram'] = vCardData.instagramHandle;
      vCard.socialUrls['twitter'] = vCardData.twitterHandle;
      vCard.socialUrls['tiktok'] = vCardData.tiktokHandle;
      vCard.socialUrls['youtube'] = vCardData.youtubeHandle;
      vCard.url = 'https://absaco.com';
      vCard.website = 'https://absaco.com';

      //save to file
      //vCard.isOrganization = true;
      vCard.version = "3.0";

      let vCardFormatted = vCard.getFormattedString();

      for (let i = 0; i < vCardFormatted.search(/;CHARSET=UTF-8/g); i++) {
        // Removing the CHARSET-UTF Stamps
        vCardFormatted = vCardFormatted.replace(';CHARSET=UTF-8', '');
        // Removing https://www.
        // vCardFormatted = vCardFormatted.replace('https://www.', '');
        // Removing https://
        // vCardFormatted = vCardFormatted.replace('https://', '');
        //console.log(i);
      }
      vCardFormatted = vCardFormatted.replace(';;;', '');
      vCardFormatted = vCardFormatted.replace(';', '');
      vCardFormatted = vCardFormatted.replace('X-SOCIALPROFILE;TYPE=facebook:', 'URL;type=facebook:');
      vCardFormatted = vCardFormatted.replace('X-SOCIALPROFILE;TYPE=linkedIn:', 'URL;type=linkedIn:');
      vCardFormatted = vCardFormatted.replace('X-SOCIALPROFILE;TYPE=twitter:', 'URL;type=twitter:');
      vCardFormatted = vCardFormatted.replace('X-SOCIALPROFILE;TYPE=tiktok:', 'URL;type=tiktok:');
      vCardFormatted = vCardFormatted.replace('X-SOCIALPROFILE;TYPE=youtube:', 'URL;type=youtube:');
      vCardFormatted = vCardFormatted.replace('X-SOCIALPROFILE;TYPE=instagram:', 'URL;type=instagram:');

      
      // Fixing Social Links
      //vCardFormatted = vCardFormatted.replace(';CHARSET=UTF-8', '');

      console.log(vCardFormatted);
      // console.log(vCard.getFormattedString());
      
      // Creating a Browser Object
      let displayName = this.getRegisteredUserInfo.displayName;
      const blob = new Blob([ vCardFormatted ], {type : 'text/vcard'});
      FileSaver.saveAs(blob, displayName + ".vcf");

    },// GUI
    OpenDialog(payload) {
      //
      this.dialog = true;
      // console.log(payload);
      // Setting the Dialog State
      let dialogText = {
        active: payload.active,
        heading: payload.heading,
        description: payload.description
      }
      this.$store.dispatch('setDialog', dialogText)

      // Setting the dialog opened
      this.dialog = true
    },
    CheckData(payload){
      let res = false
      
      if(payload != '-' && payload != ' ' && payload != '' && payload != undefined)
      {
        res = true
      }

      return res
    }
  },
  computed: {
    getUserInfo(){
    return this.$store.getters.userinfo;
    },
    getUser(){
      return this.$store.getters.user;
    },
    getAppInfo(){
      return this.$store.getters.appinfo;
    },
    getLoadingState(){
      return this.$store.getters.loadingState;
    },
    getContainerInfo() {
      this.$store.getters.containerInfo
      return this.$store.getters.containerInfo;
    },
    getRegisteredUserInfo() {
      return this.$store.getters.SearchedUser;
    }
  },
  created() {
    // console.log('URL PARAMETERS',  parsedParams.access_token);
    
    // IF the URL has any parameter only then it will work
    console.log( "URL Parser", this.GetURLParameter("user"))
    // Checking the User Availblity
    this.CheckUser()
    
    setTimeout(() => {
      this.$store.dispatch('GetUserRegistrationData', this.GetURLParameter('user')).then(() => {
        this.SearchedUser = this.getRegisteredUserInfo
        this.overlay = false
        this.UpdateQR();
      });
    },3000)

    setTimeout(() => {
      this.$store.dispatch('GetUserRegistrationData', this.GetURLParameter('user')).then(() => {
        this.SearchedUser = this.getRegisteredUserInfo
        this.overlay = false
        this.UpdateQR();
      });
    },3000)
    setTimeout(() => {
      this.SearchedUser = this.getRegisteredUserInfo
      this.UpdateQR();
      
    },5000)
    
  },
  components: {
    VueQr
  }
}
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
.styledContainer {
  border-radius: 25px;
}
.boxColor {
   background: rgba(240,240,240,0.8);
}
#tsparticles {
  position: absolute;
  width: 100%;
  z-index: -5;
}
a {
  text-decoration: none;
}
img .logo {
  z-index: 2;
}

</style>
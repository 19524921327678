<template>
<div>
  <!-- App Bar Main Container from the top black pointer -->
  <v-app-bar
    dark
  >
    <img src="../assets/logo.png"  height="32" />
    <a href="../" style="color: black; text-decoration: none"> 
      <v-toolbar-title style="color: #FFF;"> 
        <span class="ml-2"> {{ getAppInfo.name }} </span>
      </v-toolbar-title> 
    </a>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-information</v-icon>
    </v-btn>
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to right, #000000, #434343"
      ></v-img>
    </template>
  </v-app-bar>


  <!-- loading Progress -->
  <v-container
    dark
    class="radius boxColor"
    style="box-radius: 20px;"
  >


    <!-- Page Heading -->
    <h1 class=" my-3">
          Container Track and Trace
    </h1>
    <!-- Search Pager Header -->
    <v-row
    >
      <v-col
        class=""
        cols="12"
      >
      </v-col>
    </v-row>
    <v-row
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <!-- MAP EMBEDDED -->
        <h3>An interactive map will be here</h3>
        <!-- <img src="../assets/map.png"  height="100%" /> -->
      </v-col>
    </v-row>
    <!-- Main BODY -->
      <v-row no-gutters>
        <v-col
          class="text-center"
          cols="6"
          sm="12"
          md="6"
          xl="6"
          >
          <v-card
            class="mx-auto ma-5 boxColor"
            max-width="400"
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5" v-text="getRegisteredUserInfo.FullName">
                </v-list-item-title>
                <v-list-item-subtitle v-text="getRegisteredUserInfo.Email"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-text-field
                v-model="SearchUserID"
                prepend-icon="mdi-nfc-search-variant"
                filled
                clear-icon="mdi-close-circle"
                clearable
                label="Search ID"
                type="Search by User ID"
              ></v-text-field> 
            </v-card-text>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-phone</v-icon>
                
              </v-list-item-icon>
              <v-list-item-content>Location</v-list-item-content>
              <v-list-item-subtitle v-text="getRegisteredUserInfo.MobileNo"></v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-phone-classic</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle v-text="getRegisteredUserInfo.WorkNo"></v-list-item-subtitle>
            </v-list-item>
            <v-list-item-avatar
              tile
              size="256"
              color="grey"
            ></v-list-item-avatar>

            <v-list class="transparent">
              <v-list-item
              >
                <v-list-item-title v-text="getRegisteredUserInfo.Address"></v-list-item-title>

                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle class="text-right" v-text="getRegisteredUserInfo.Country">
                  
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                elevation="2"
                outlined
                v-on:click="FetchUserInfo"
              ><v-icon>mdi-cloud-search Seach More</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          class="text-center"
          cols="6"
          sm="12"
          md="6"
          xl="6"
        >
          <!-- QR Generator card -->
          <v-card
            class="mx-auto boxColor"
            max-width="400"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  QR Generator 
                </div>
                <v-list-item-subtitle>QR is suppose to be visible inside the Canvas, After the profile is loaded</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>

            <v-card-text>
              <a :href="downloadURL" download>
                <vue-qr
                  id="baseQR"
                  elevation="2"
                  :size="256"
                  :text="qrURL"
                  :logoSrc="src"
                  :dotScale=".75"
                  colorDark="rgb(0, 71, 171)"
                  backgroundDimming="rgb(220,220,220)"
                  :gifBgSrc="gifBgSrc2"
                ></vue-qr>
              </a>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="gray"
                text
                @click="UpdateQR()"
              >
                Generate QR
              </v-btn>
              <a :href="downloadURL" download>
                <v-btn
                  color="gray"
                  text
                  @click="DownloadQR()"
                >
                  Download QR
                </v-btn>
              </a>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
  

</div>
  
</template>

<script>

  export default {
    name: 'Home',

    data: () => ({
    valid: false,

    }),
    methods: {
      Practing() {
        console.log("Log Infront");
        console.log(this.NumberType);
        this.valid = true;
        setTimeout(() => {
          this.valid = false;
          //Setting up the routes
          this.$router.push(this.trackingID)
        }, 1000)
      },
      FetchContainerInfo() {
        //Sending the instructions to the state
        // let pageId = String(this.$route.params.id);
        this.$store.dispatch('GetAuthentication')
        // this.$store.dispatch('setContainerInformation', {
        //   id: pageId
        // })
      },
      RefreshContainerInfo() {
        //Getting the getters
        this.trackingInfo = this.$store.getters.containerInfo
      }
    },
    watch: {
      //Whenever the text field have something in it,
      // trackingID() {
      //   if(this.trackingID.length > 0) 
      //   {
      //     this.valid = true
      //   } else {
      //     this.valid = false
      //   }
      // }
    },
    computed: {
      getAppInfo(){
        return this.$store.getters.appinfo;
      },
      getContainerInfo() {
        this.$store.getters.containerInfo
        return this.$store.getters.containerInfo;
      }
    },
    created() {
      // SENDING THE INFO TO THE STATE
      let pageId = String(this.$route.params.id);
      this.$store.dispatch('setContainerInformation', {
        id: pageId
      })

      //Sending the POST
          let data = new FormData();
          data.append('id', String(this.$route.params.id));
          //console.log("Setting Tracking ID: ", this.$route.params.id)
          this.axios({
          method: 'post',
          url: 'https://getabsa.techerupt.com',
          headers: {
            // "Access-Control-Allow-Origin" : "*",
            // 'Access-Control-Allow-Methods': 'POST',
            'Content-Type': 'multipart/form-data',
          },
          data: data
          }).then((response) => {
          this.trackingInfo = response.data;
          console.log(JSON.stringify(response.data))

          // After getting all the data from the Server
          
          })
    }
  }
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
#tsparticles {
  position: absolute;
  width: 100%;
  z-index: 0;
}
img .logo {
  z-index: 2;
}
.boxColor {
   background: rgba(230,230,230,0.6); 
}
</style>
<template>
<div>
  <Particles
      id="tsparticles"
      style="position: absolute; width: 100%; height: 100%;"
      :options="{
          background: {
              color: {
                  value: 'transparent',
              }
          },
          fpsLimit: 30,
          interactivity: {
              detectsOn: 'canvas',
              events: {
                  onClick: {
                      enable: true,
                      mode: 'push'
                  },
                  onHover: {
                      enable: false,
                      mode: 'repulse'
                  },
                  resize: true
              },
              modes: {
                  bubble: {
                      distance: 600,
                      duration: 10,
                      opacity: 0.8,
                      size:32
                  },
                  push: {
                      quantity: 1
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4
                  }
              }
          },
          particles: {
              color: {
                  value: '#000'
              },
              links: {
                  color: '#444',
                  distance: 150,
                  enable: true,
                  opacity: .3,
                  width: .5
              },
              collisions: {
                  enable: false
              },
              move: {
                  direction: 'none',
                  enable: true,
                  outMode: 'none',
                  random: false,
                  speed: .2,
                  straight: true
              },
              number: {
                  density: {
                      enable: true,
                      value_area: 1200
                  },
                  value: 64
              },
              opacity: {
                  value: 0.25
              },
              shape: {
                  type: 'polygon',
                  options: {
                    polygon: [
                    {
                      sides: 3
                    }
                    ]
                  },
              },
              size: {
                  random: true,
                  value: 5
              }
          },
          detectRetina: false
      }"
    />

  <!-- Overlay until the Authorization -->
  <v-overlay :value="overlay" opacity=".98">
    <v-container>
      <v-row justify="center" >
        <h1 class="crisp">Please Wait</h1>
      </v-row>
      <v-row justify="center" class="mb-10">
        <h3 class="crisp">while we are connecting with our services</h3>
      </v-row>
      <v-row justify="center" class="mb-10">
        <v-progress-circular
          indeterminate
          width="1"
          size="64"
        ></v-progress-circular>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="white--text btGradient"
          @click="overlay = !overlay"
        >
          Expedite Authentication
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-overlay>

  <!-- Result -->
  <v-container class="boxColor styledContainer" >
    <v-row >
      <!-- Managing Existing Data -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        offset="0"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
        class=" mt-15 m-20"
      >
      <h1>Manage Users</h1>
      <h4>Edit all the registered users in the QR generation system.</h4>
      </v-col>


      <!-- MAIN BODY -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        offset="0"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
        class="m-20"
      >
      <v-card class="boxColor styledContainer" elevation="0">
        <v-card-title>
          <h3>  Registered Users </h3>   
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          dense 
          show-select
          :search="search"
          item-key="userID"
          :single-select="true"
          v-model="selectedUser"
          :headers="headers"
          :items="getAllRegisteredUserInfo"
          :items-per-page="5"
          class="boxColortable"
        ></v-data-table>
      </v-card>
      </v-col>

      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        offset="0"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
        class="m-20"
      >
        <h1>Edit Panel</h1>
        <h4>Please Select a Registered User</h4>
        <v-row no-gutters v-if="selectedUser[0]">
          <!-- First Row -->
          <v-col cols="4" class="pa-5">
            <v-text-field
              label="Display Name"
              v-model="selectedUser[0].displayName"
              :rules="textfieldrules"
              clearable
              tabindex="1"
              filled
              required
            ></v-text-field>
            <v-text-field
              label="Company Name"
              v-model="selectedUser[0].companyName"
              placeholder="ALBARRAK Group"
              :rules="textfieldrules"
              clearable
              tabindex="1"
              filled
              required
            ></v-text-field>
            <!-- <v-text-field
              label="First Name"
              v-model="selectedUser[0].firstName"
              :rules="textfieldrules"
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Last Name"
              v-model="selectedUser[0].lastName"
              :rules="textfieldrules"
              clearable
              tabindex="1"
              required
            ></v-text-field> -->
            <v-text-field
              label="Designation"
              v-model="selectedUser[0].designation"
              :rules="textfieldrules"
              clearable
              tabindex="1"
              filled
              required
            ></v-text-field>
            <v-text-field
              label="website"
              v-model="selectedUser[0].website"
              clearable
              tabindex="1"
              filled
              required
            ></v-text-field>
            <v-select
              :items="this.countryList"
              tabindex="7"
              item-text="name"
              item-value="name"
              v-model="selectedCountry"
              filled
              return-object
              label="Country"
            ></v-select>
            <v-text-field
              :disabled="selectedCountry.length <= 0"
              tabindex="8"
              v-model="selectedCity"
              filled
              clearable
              label="State"
            ></v-text-field>
            <v-text-field
              label="Alternative Mobile Number"
              v-model="selectedUser[0].altMobileNo"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="LinkedIn Handle"
              v-model="selectedUser[0].linkedInHandle"
              :rules="linkedInfieldrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Twitter Handle"
              v-model="selectedUser[0].twitterHandle"
              :rules="twitterfieldrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Facebook Handle"
              v-model="selectedUser[0].facebookHandle"
              :rules="fbfieldrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Youtube Handle"
              v-model="selectedUser[0].youtubeHandle"
              :rules="youtuberules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <!-- <v-select
              :disabled="selectedCountry.length <= 0"
              :items="this.cityList"
              tabindex="8"
              item-text="name"
              item-value="name"
              v-model="selectedCity"
              return-object
              label="State"
            ></v-select> -->
          </v-col>
          <!-- Second Row --> 
          <v-col cols="4" class="pa-5">
            <v-text-field
              label="Work #"
              v-model="selectedUser[0].workNo"
              :rules="contactnumberfieldrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field><v-text-field
              label="Secondary Work #"
              v-model="selectedUser[0].secWorkNo"
              :rules="contactnumberfieldrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Mobile #"
              v-model="selectedUser[0].mobileNo"
              :rules="contactnumberfieldrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Fax"
              v-model="selectedUser[0].fax"
              :rules="contactnumberfieldrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Email Official"
              v-model="selectedUser[0].emailOfficial"
              filled
              :rules="emailfieldrules"
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Email"
              v-model="selectedUser[0].email"
              filled
              :rules="emailfieldrules"
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Address"
              v-model="selectedUser[0].address"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Secondary Address"
              v-model="selectedUser[0].secAddress"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Instagram Handle"
              v-model="selectedUser[0].instagramHandle"
              :rules="instagramrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
            <v-text-field
              label="Tiktok Handle"
              v-model="selectedUser[0].tiktokHandle"
              placeholder="https://www.tiktok.com/@absaco_albarrakgroup"
              :rules="tiktokrules"
              filled
              clearable
              tabindex="1"
              required
            ></v-text-field>
          </v-col>
          <!-- Third Row -->
          <v-col cols="4" class="pa-5">
            <!-- <v-text-field
              label="Twitter Handle"
              v-model="selectedUser[0].twitterHandle"
              clearable
              tabindex="1"
              required
            ></v-text-field> -->
            <!-- Options --> 
            <v-row >
              <v-col class="self-center">
                <a v-if="this.selectedUser[0].displayName != ''" :href="downloadURL" target="_blank">
                  <img :src="this.downloadURL" alt="User is not selected or available">
                  <!-- :text="qrURL" 
                  
                    :logoSrc="src"
                    :dotScale=".75"
                    :gifBgSrc="gifBgSrc2"
                  
                  -->
                  
                </a>
                <v-checkbox
                  v-model="offlineQR"
                  label="Auto-Generate QR"
                ></v-checkbox>
                <vue-qr
                  id="baseQR"
                  v-if="this.offlineQR"
                  :logoSrc="src"
                  :dotScale=".75"
                  :gifBgSrc="gifBgSrc2"
                  :name="this.selectedUser[0].displayName"
                  elevation="2"
                  :size="256"
                  :text="qrURL" 
                  colorDark="rgb(0, 71, 255)"
                  backgroundDimming="rgb(220,220,220)"
                ></vue-qr>
                
                <v-btn
                    tabindex="9"
                    block
                    x-large
                    dark
                    class="ma-5 ml-0"
                    @click="UploadQROnly"
                  >
                  Upload Generated QR
                </v-btn>
              </v-col>
            </v-row>
            <div>
              Updating the existing User information in the database.
            </div>
            <v-btn
                tabindex="9"
                block
                :disabled="!basicFormisValid"
                x-large
                dark
                class="ma-5 ml-0 btGradient"
                @click="UpdateResgisteredUserInfo"
              >
              Update
              
            </v-btn>
            <!-- <v-btn
                tabindex="9"
                block
                x-large
                dark
                class="ma-5 ml-0 btGradient"
                @click="SetFireStore"
              >
              SetFirestore
            </v-btn> -->
            
            <v-btn
                tabindex="9"
                block
                x-large
                dark
                class="ma-5 ml-0 btGradientSec"
                @click="ClearSelection"
              >
              Clear Form
            </v-btn>
            <div>
              Delete the existing User.
            </div>
            <v-btn
                tabindex="9"
                color="Warning"
                block
                x-large
                dark
                class="ma-5 ml-0 "
                @click="DeleteUserByID"
              >
              DELETE USER!
            </v-btn>
            
          </v-col>
        </v-row>
      </v-col>


      <!-- guide text -->
      <v-col
        class="mb-5 text-center"
        cols="12"
      >
        <a href="https://absaco.com"><v-btn class="m-20">Visit www.ABSACO.com</v-btn></a>
        
        <h3 class="headline font-weight-bold mb-1">
        </h3>

        <p>
          <span style="color: #999; font-weight: bolder"> 
          App Version
          {{ getAppInfo.version }}
          </span>
        </p>



      </v-col>
    </v-row>
  </v-container>

</div>
  
</template>

<script>
import VueQr from 'vue-qr'
import src from "../assets/logo.png";
import gifBgSrc2 from "../assets/map.gif";


export default {
  name: 'Home',

  data: () => ({
  headers: [
    {
      text: 'Display Name',
      align: 'start',
      sortable: true,
      value: 'displayName',
    },
    { text: 'Serial Key', value: 'userID' },
    // { text: 'firstName', value: 'firstName' },
    // { text: 'lastName', value: 'lastName' },
    { text: 'Company Name', value: 'companyName' },
    { text: 'Mobile #', value: 'mobileNo' },
    { text: 'Designation', value: 'designation' },
    { text: 'Email Official', value: 'emailOfficial' },
    { text: 'Email Personal', value: 'email' },
    { text: 'Work #', value: 'workNo' },
    { text: 'Secondary Work #', value: 'secWorkNo' },
    { text: 'City/Region', value: 'city' },
    { text: 'Country', value: 'country' },
    { text: 'Fax', value: 'fax' },
    { text: 'Address', value: 'address' },
    { text: 'Alternative Mobile', value: 'altMobileNo' },
    { text: 'Alternative Address', value: 'secAddress' },
    { text: 'Website', value: 'website' },
    { text: 'LinkedIn', value: 'linkedInHandle' },
    { text: 'Facebook', value: 'facebookHandle' },
    { text: 'Twitter', value: 'twitterHandle' },
    { text: 'Instagram', value: 'instagramHandle' },
    { text: 'Tiktok', value: 'tiktokHandle' },
    { text: 'Youtube', value: 'youtubeHandle' }
  ],
  AllRegisteredUsers: [
    {
      name: 'Frozen Yogurt',
      calories: 159,
      fat: 6.0,
      carbs: 24,
      protein: 4.0,
      iron: '1%',
    },
  ],

  textfieldrules: [
    v => !!v || 'This field is required',
    v => /^[^\s]+(\s+[^\s]+)*$/.test(v) || 'No WhiteSpaces in the start/end'
  ],
  emailfieldrules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
  ],
  contactnumberfieldrules: [
      //v => !!v || 'A valid Number is required',
      v => (!/[^\d+\-+$]/.test(v)) || 'No Character/Alphabets Allowed',
      //v => (!/[^0-9]/.test(v)) || 'No Character/Alphabets Allowed',
      v => (v && v.length >= 8) || 'Number must be more than 8 characters'
  ],
  twitterfieldrules: [
      v => (v.includes("https://twitter.com/") == true) || 'Should be a twitter Profile link'
  ],
  fbfieldrules: [
      v => (v.includes("https://www.facebook.com/") == true) || 'Should be a Facebook Profile link'
  ],
  linkedInfieldrules: [
      v => (v.includes("https://www.linkedin.com/") == true) || 'Should be a LinkedIN Profile link'
  ],
  instagramrules: [
      v => (v.includes("https://www.instagram.com") == true) || 'Should be a Instagram Profile link'
  ],
  tiktokrules: [
      v => (v.includes("https://www.tiktok.com/") == true) || 'Should be a Tiktok Profile link'
  ],
  youtuberules: [
      v => (v.includes("https://www.youtube.com") == true) || 'Should be a Youtube Profile link'
  ],
  userID: '',
  logoSrc: '',
  selectedCountry: '',
  selectedCity: '',
  qrURL: '',
  //GUI Helpers
  countryList: [],
  cityList: [],


  selectedUser: [
    {
      //firstName: '',
      //lastName: '',
      companyName: 'ALBARRAK Group',
      displayName: '',
      designation: '',
      secWorkNo: '',
      mobileNo: '',
      altMobileNo: '',
      workNo: '',
      fax: '',
      email: '',
      emailOfficial: '',
      address: '',
      secAddress: '',
      website: 'absaco.com',
      linkedInHandle: '',
      facebookHandle: '',
      twitterHandle: '',
      tiktokHandle: 'https://www.tiktok.com/@absaco_albarrakgroup',
      youtubeHandle: '',
      instagramHandle: '',
      selectedCountry: '',
      selectedCity: '',
      selectedRegion: '',
    }
  ],
  valid: false,
  src,
  gifBgSrc2,
  SearchUserID: '',
  offlineQR: false,
  SearchedUser: {},
  search: '',

  // Overlay
  overlay: false,

  //qrURL: '',
  VCFQRupdate: false,
  downloadURL: '',

  }),
  methods: {
    FetchUserInfo() {
      //Sending the instructions to the state
      this.overlay = true
      setTimeout(() => {
      this.$store.dispatch('GetUserRegistrationData', this.SearchUserID).then(() => {
        this.SearchedUser = this.getRegisteredUserInfo
        this.UpdateQR();
        this.overlay = false
      });
    },500)
    },
    UpdateResgisteredUserInfo() {

      this.overlay = true;
      // Date to be updated
      let data = {
        displayName: this.selectedUser[0].displayName,
        linkedInHandle: this.selectedUser[0].linkedInHandle,
        facebookHandle: this.selectedUser[0].facebookHandle,
        tiktokHandle: this.selectedUser[0].tiktokHandle,
        youtubeHandle: this.selectedUser[0].youtubeHandle,
        twitterHandle: this.selectedUser[0].twitterHandle,
        instagramHandle: this.selectedUser[0].instagramHandle,
        userID: this.selectedUser[0].userID,
        companyName: this.selectedUser[0].companyName,
        secWorkNo: this.selectedUser[0].secWorkNo,
        // firstName: this.selectedUser[0].firstName,
        // lastName: this.selectedUser[0].lastName,
        fax: this.selectedUser[0].fax,
        email: this.selectedUser[0].email,
        emailOfficial: this.selectedUser[0].emailOfficial,
        designation: this.selectedUser[0].designation,
        mobileNo: this.selectedUser[0].mobileNo,
        altMobileNo: this.selectedUser[0].altMobileNo,
        workNo: this.selectedUser[0].workNo,
        address: this.selectedUser[0].address,
        secAddress: this.selectedUser[0].secAddress,
        website: this.selectedUser[0].website,
        country: this.selectedCountry.name,
        city: this.selectedCity.name,
      }

      this.$store.dispatch('UpdateUserData', data).then(() => {
        setTimeout(() => {
          this.overlay = false;
          // Refreshing all data 
          this.$store.dispatch("GetAllUserRegistrationData");
        }, 1000)
      });
    },
    ClearSelection() {

      this.overlay = true;
      // Clear the Form
      this.selectedUser = [{}];

      setTimeout(() => {
        this.overlay = false;
      }, 500)
    },
    DeleteUserByID() {
      // Date to be Deleted
      if (this.selectedUser[0].userID) {
        this.overlay = true;
        this.$store.dispatch('DeleteUserByID', this.selectedUser[0].userID).then(() => {
        setTimeout(() => {
          this.overlay = false;
          // Refreshing all data 
          this.$store.dispatch("GetAllUserRegistrationData");
        }, 1000)
      });
      } else {
        console.log("Cannot Delete!");
        this.OpenDialog({active: true, heading: 'Cannot Delete!', description: 'Please select any User from the List'})
      }
    },
    SetFireStore() {
       this.$store.dispatch('SetFireStoreSampleData');
    },
    SetQRImages() {
       this.$store.dispatch('SetFireStoreQRImages');
    },
    UpdateQR() {
      //Getting the getters
      this.qrURL = this.getAppInfo.domain + '/search?user=' + this.selectedUser[0].userID;
      //this.downloadURL = document.getElementById('baseQR').src;

      //this.VCFQRupdate ? false : true ;
    },
    UploadQROnly() {
      // Sending QR Image
      // Date to be updated
      let data = {
        displayName: this.selectedUser[0].displayName,
        qrImage: this.getQRImage
      }
      this.$store.dispatch('PushOnlyQRBasedonDisplayName', data).then(() => {
        setTimeout(() => {
          this.overlay = false;
          // Refreshing all data 
          this.$store.dispatch("GetAllUserRegistrationData");
        }, 1000)
      });
    },
    RefreshUserInfo() {
      //Getting the getters
      this.trackingInfo = this.$store.getters.containerInfo
    },
    GetURLParameter(input) {
      // Getting the information from the URL
      // let URLparameter = this.$route;
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let parsedParams = urlParams.get(input)
      
      return parsedParams;

    },  //Login
    checkUserLogin() {
      // Checking if the user is already signed In
      this.$store.dispatch('userSession').then(() => {
        // Check is user is LOGGED IN?
        setTimeout(() => {
          if (Object.keys(this.getUserInfo) == 0  || this.getUserInfo == null || this.getUserInfo == undefined){
            //if user is null then the app will notify after 2 seconds
            if (this.$route.name != 'Login' || this.$route.name != 'Registration') {
              setTimeout(() => {
                console.log("YOU ARE NOT LOGGED IN");
                this.$router.push({'name': 'Login'})
                //this.$store.dispatch('setDialog')
                this.OpenDialog({active: true, heading: 'The Session is not valid', description: 'Please login again with correct credentials'})
              }, 1000)
            }
          }else{
            console.log("Logged IN");
          }
        }, 2000)
      });
    },  // GUI
    OpenDialog(payload) {
      //
      // console.log(payload);
      // Setting the Dialog State
      let dialogText = {
        active: payload.active,
        heading: payload.heading,
        description: payload.description
      }
      this.$store.dispatch('setDialog', dialogText)

      // Setting the dialog opened
      this.dialog = true
    }
  },
  computed: {
    getUserInfo(){
    return this.$store.getters.userinfo;
    },
    getUser(){
      return this.$store.getters.user;
    },
    getAppInfo(){
      return this.$store.getters.appinfo;
    },
    getLoadingState(){
      return this.$store.getters.loadingState;
    },
    getContainerInfo() {
      this.$store.getters.containerInfo
      return this.$store.getters.containerInfo;
    },
    getRegisteredUserInfo() {
      return this.$store.getters.SearchedUser;
    },
    getAllRegisteredUserInfo() {
      return this.$store.getters.GetAllRegisteredUsers;
    },
    basicFormisValid() {
      return (
        //this.selectedUser[0].firstName &&
        this.selectedUser[0].companyName &&
        this.selectedUser[0].displayName &&
        //this.selectedUser[0].lastName &&
        this.selectedUser[0].mobileNo &&
        this.selectedUser[0].emailOfficial &&
        this.selectedUser[0].designation &&
        this.selectedUser[0].workNo &&
        this.selectedCountry 
      );
    },
    getQRImage(){
      let downloadURL = document.getElementById('baseQR').src; 
      return downloadURL;
    },
  },
  created() {
    // console.log('URL PARAMETERS',  parsedParams.access_token);
    
    // IF the URL has any parameter only then it will work
    // console.log( "URL Parser", this.GetURLParameter("user"))
    // setTimeout(() => {
    //   this.$store.dispatch('GetUserRegistrationData', this.GetURLParameter('user')).then(() => {
    //     this.SearchedUser = this.getRegisteredUserInfo
    //     this.overlay = false
    //     this.UpdateQR();
    //   });
    // },3000)

    // setTimeout(() => {
    //   this.$store.dispatch('GetUserRegistrationData', this.GetURLParameter('user')).then(() => {
    //     this.SearchedUser = this.getRegisteredUserInfo
    //     this.overlay = false
    //     this.UpdateQR();
    //   });
    // },3000)
    // setTimeout(() => {
    //   this.SearchedUser = this.getRegisteredUserInfo
    //   this.UpdateQR();
    // },5000)
    //console.log(this.getUserInfo);

    // Checking the Role _ only if Data Update / Admin
    let check = setTimeout(() => {
      // If user exist
      if(this.getUser != null){
        // if user information exist
        if (this.getUserInfo.authRole != "Data Update" && this.getUserInfo.authRole != "Admin")
        {
          let dialog = {
              active: true,
              heading: 'You are not Allowed',
              description: "Please get permission to active this feature, Your Exiting Role is: " + this.getUserInfo.authRole
            }
          this.$store.dispatch("setDialog", dialog);
          // Routing to the Login State
          this.$router.push({'name': 'Login'})
        }
      // console.log(this.countryList)
      } else if (this.getUser == null) {
        // If user does not exist
        let dialog = {
              active: true,
              heading: 'You are not Logged In',
              description: "Please Login."
            }
          this.$store.dispatch("setDialog", dialog);
          // Routing to the Login State
          this.$router.push({'name': 'Login'})
          clearInterval(check);
      }
    }, 5000)
    
    // Random number Generator
    setTimeout(() => {
      this.qrURL = this.getAppInfo.domain + '/search?user=' + this.userID;
      this.countryList = require("../assets/data/countries.json");
      // console.log(this.countryList)
    }, 2000)

    //Fetch all the users on the Platform
    
    this.$store.dispatch("GetAllUserRegistrationData");
  },
  components: {
    VueQr
  },
  watch: {
    //On User Infomation Verify
    getUser (value){
      if (value == null && value == undefined) {
        console.log('Watch Kicked')
        this.checkUserLogin();
      } 
    },
    selectedUser (value){
      // console.log("hit")
      //Initiate Form Build
      // Setting the country object
      if (value[0].country) {
      for (const country of this.countryList) {
        if (value[0].country == country.name) {
          this.selectedCountry = country;
          // country.states.forEach(city => {
          //   this.cityList.push(city);
          // });
        }
      }

      // Setting offline QR to false
      this.offlineQR = false
      // Generate new QR
      this.qrURL = this.getAppInfo.domain + '/search?user=' + this.selectedUser[0].userID;
      // this.UpdateQR();
      // this.UpdateQR();
      }
      
      
      // Setting up URL
      this.downloadURL = this.getAppInfo.downloadServer + "/" + this.selectedUser[0].displayName + ".gif";
      
      // console.log(this.downloadURL)
    }
  }
}
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}

.styledContainer {
  padding: 5%;
  border-radius: 25px;
}
.boxColor {
   background: rgba(240,240,240,0.5);
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
#tsparticles {
  position: absolute;
  width: 100%;
  z-index: -5;
}
a {
  text-decoration: none;
}
img .logo {
  z-index: 2;
}
.boxColortable {
   background: rgba(240,240,240,0.5);
}


</style>
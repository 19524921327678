
import Vue from 'vue'
import Router from 'vue-router'
// import express
// import express from "express";
// Components
import Home from '../components/Home.vue'
import getQRImage from '../components/getQRImage.vue'
import Search from "../components/Search.vue"
import Manage from "../components/Manage.vue"
import callback from "../components/callback.vue"
import Login from "../components/Login.vue"
import Registration from "../components/Registration.vue"


// init express router
// const expressRouter = express.Router();

Vue.use(Router);

export const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/search',
      name: 'Search',
      component: Search
    },
    {
      path: '/manage',
      name: 'Manage',
      component: Manage
    },
    {
      path: '/callback',
      name: 'callback',
      component: callback
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login
    },
    {
      path: '/Registration',
      name: 'Registration',
      component: Registration
    },
    {
      path: '/getQRImage',
      name: 'getQRImage',
      component: getQRImage
    }

];
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

// import { getFirestore } from "firebase/firestore";
// import { initializeApp } from 'firebase/app';


// importing Firebase
// import * as firebase from 'firebase'
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// import express
// import express from "express";
// import cors
// import cors from "cors";
import Vuevcard from 'vue-vcard'; 
import vueCountriesCities from "vue-countries-cities";
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router';
import { store } from './store/store'
import { routes } from './router'
import vuetify from './plugins/vuetify'
import Particles from "particles.vue";
import VueCookies from 'vue-cookies'


// import { apply } from 'core-js/fn/reflect';

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAFB33NGR9sXmcLkylSGUP_V3XHGVAqFr8",
//   authDomain: "qrabsa-c54b0.firebaseapp.com",
//   databaseURL: "https://qrabsa-c54b0-default-rtdb.firebaseio.com",
//   projectId: "qrabsa-c54b0",
//   storageBucket: "qrabsa-c54b0.appspot.com",
//   messagingSenderId: "770940410626",
//   appId: "1:770940410626:web:92612bd496c89be877801b"
// };

// const app = initializeApp(firebaseConfig)
// // Initialize Cloud Firestore and get a reference to the service
// const db = getFirestore(app);

Vue.config.productionTip = false

Vue.use(Particles);
Vue.use(VueRouter);
Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.component('vue-vcard', Vuevcard);
Vue.component("countriesCities", vueCountriesCities);


// Setting up base cookie
Vue.$cookies.config('BlackMesh')
Vue.$cookies.set('Type','default');
Vue.$cookies.set('Miss','default');

// Registring Routes
const router = new VueRouter({
  routes,
  mode: 'history'
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')


<template>
  <v-app >
    <v-main class="bgBack">
       <!-- Overlay until the Loading -->
      <v-overlay :value="getMainLoading" opacity=".98">
      <!-- <v-overlay :value="true" opacity=".98"> -->
        <v-container>
          <v-row justify="center" >
            <h1 class="crisp">Please Wait</h1>
          </v-row>
          <v-row justify="center" class="mb-10">
            <h3 class="crisp">Data Loading</h3>
          </v-row>
          <!-- Loading Gif -->
          <v-row justify="center" class="mb-10">
            <img src="./assets/loading.gif" width="75%" alt="Loading...">
          </v-row>
          <v-row justify="center" class="mb-10">
            <v-progress-circular
              indeterminate
              width="1"
              size="64"
            ></v-progress-circular>
          </v-row>
        </v-container>
      </v-overlay>
        <!-- App Bar Main Container from the top black pointer -->
        <v-app-bar dark absolute v-if="this.getUser != null || this.$route.name == 'Search'">
          <img src="./assets/logo.png"  height="32" />
          <a href="./" style="color: black; text-decoration: none"> 
            <v-toolbar-title style="color: #FFF;"> 
              <span class="ml-2"> {{ getAppInfo.name }} </span>
            </v-toolbar-title> 
          </a>
          <v-spacer></v-spacer>
          <router-link to="/search?user=BASIC" hidden>
            <v-btn icon >
              <v-icon>mdi-search-web</v-icon>
            </v-btn>
          </router-link>
          <router-link to="/" v-if="this.getUserInfo.authRole == 'Data Entry' || this.getUserInfo.authRole == 'Admin'" >
            <v-btn icon>
              <v-icon>mdi-book-arrow-down</v-icon>
            </v-btn>
          </router-link>
          <router-link to="/manage" v-if="this.getUserInfo.authRole == 'Data Update' || this.getUserInfo.authRole == 'Admin'" >
            <v-btn icon>
              <v-icon>mdi-book-edit</v-icon>
            </v-btn>
          </router-link>
          <div>
            {{ this.getUserInfo.fullName }}
          </div>
          <router-link to="/Login" v-if="this.getUserInfo.authRole">
            <v-btn @click="SignOut" icon>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </router-link>
          <template v-slot:img="{ props }">
            <v-img
              v-bind="props"
              gradient="to right, #2e3238, #353d4e"
            ></v-img>
          </template>
        </v-app-bar>
        <!-- DIALOGS -->
        <v-dialog
          v-model="dialog"
          persistent
          max-width="400"
        >
          <v-card>
            <v-card-title >
              <h2> {{ getDialogText.heading }} </h2>
            </v-card-title>

            <v-card-text>
              {{ getDialogText.description }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="btGradient"
                dark
                x-large
                @click="CloseDialog"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- avatar -->
      <router-view v-on:dialog="OpenDialog"></router-view>
      <!-- <HelloWorld/> -->
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
// import Home from './components/Home';
// 

export default {
  name: 'App',

  components: {
    // HelloWorld,
    // Home,
  },

  data: () => ({
    //
    dialog: false,
    snackbar: false,
    snackbarText: 'Pleases Login!',
    VarVisible: false,
    //GUI
    avatarMenuItems: [
      { title: 'About', function: 'dialog', heading: 'About', description: 'Its all about the containers and the tracking services'},
      { title: 'SignOut', function: 'signout' },
    ]
  }),
  created() {
    console.log("App Started!");
    this.$store.dispatch('userSession');
    this.checkUserLogin();
    // setTimeout(() => {
      // let user = this.$store.getters.user;
      // if (user === null) {
      //   console.log('created user Error')
      //   if (this.$route.name != 'Login' && this.$route.name != 'Registration')
      //   {
      //     this.$router.go('/login')
      //   }
        
      // }
    // }, 1500)
    
    // Setting up the Welcome
    this.$store.dispatch('setSnackBarText', 'Welcome! Please Login')
    this.snackbarText = this.getSnackBarText;
    this.snackbar = true

  },
  computed: {
    getUserInfo(){
      return this.$store.getters.userinfo;
    },
    getUser(){
      return this.$store.getters.user;
    },
    getRedirector(){
      return this.$store.getters.Redirector;
    },
    getDialogText(){
      return this.$store.getters.dialogText;
    },
    getMainLoading(){
      // console.log(this.$store.getters.loadingState.mainLoading);
      return this.$store.getters.loadingState.mainLoading;
    },
    getSnackBarText(){
      return this.$store.getters.loadingState;
    },
    getAvatarText(){
      return this.$store.getters.avatarText;
    },
    getAppInfo(){
      return this.$store.getters.appinfo;
    }
  },
  methods: {
    //Login
    checkUserLogin() {
      // Setting the Dialog
      // Checking if the user is already signed In
      this.$store.dispatch('userSession').then(() => {
        // Check is user is LOGGED IN?
        setTimeout(() => {
          if (this.getUser === 0 || this.getUser == null || this.getUser == undefined){
            //if user is null then the app will notify after 2 seconds
            if (this.$route.name != 'Login' && this.$route.name != 'Registration' && this.$route.name != 'Search') {
              setTimeout(() => {
                // console.log("YOU ARE NOT LOGGED IN");
                // this.$router.push({'name': 'Login'})
                this.OpenDialog({active: true, heading: 'The Session is not valid', description: 'Please login again with correct credentials'})
              }, 1000)
            }
          } 
        }, 2000)
      });
    },
    redirect() {
      // Something went wrong
      this.OpenDialog({active: true, heading: 'The Session is not valid', description: 'Something went wrong, Please try login again with correct credentials'})
      setTimeout(() => {
        //Setting the Redirector
        this.$router.go({'name': this.getRedirector.routeName});
      }
      , 2000)
      
      // Setting back to null
      this.$store.dispatch('SET_REDIRECTOR', {active: false, routeName: 'Login'})
    },
    // GUI
    OpenDialog(payload) {
      //
      this.dialog = true;
      // console.log(payload);
      // Setting the Dialog State
      let dialogText = {
        active: payload.active,
        heading: payload.heading,
        description: payload.description
      }
      this.$store.dispatch('setDialog', dialogText)

      // Setting the dialog opened
      this.dialog = true
    },
    CloseDialog() {
      this.dialog = false;
      //
      // console.log(payload);
      // Setting the Dialog State
      let dialogText = {
        active: false,
        heading: "Unknown Error Occured!",
        description: "Please try again later"
      }
      setTimeout(() => {
        this.$store.dispatch('setDialog', dialogText)
      }, 500)
      
      // Setting the dialog opened
      this.dialog = false
    },
    listMenuActions(payload){
      // Getting the type of action
      switch (payload.function) {
        case 'dialog':
          // eslint-disable-next-line no-case-declarations
          let data = {
            heading: payload.heading,
            description: payload.description
          }
          this.OpenDialog(data)
          break;
        case 'signout':
          // console.log('Signing Out!');
          this.$store.dispatch('userSignOut');

          // Recheck the user
          this.checkUserLogin();
          break;
      
        default:
          break;
      }
    },
    SignOut(){
      // Logging Out
      this.$store.dispatch('userSignOut').then(() => {
      });
    }
  },
  watch: {
    // On User Infomation Verify
    getUser (){
      //console.log("App Main Watcher", Object.keys(this.getUser))
      if (Object.keys(this.getUser) != 0) {
        // console.log('Watch Kicked')
        this.checkUserLogin();
      } 
      // else if (this.getUser == null) {
      //   this.checkUserLogin();
      // }
    },
    SetLoading (){
      if (this.getMainLoading == true) {
        // console.log('Watch Kicked')
        this.checkUserLogin();
      } 
    },

    //Watching the redirector
    'getDialogText.active' (value){
      this.dialog = value;
    },
    //Watching the UserInformation
    // 'getRedirector.active'(){
    //   console.log('watch fired')
    //   this.redirect();
    // }
  }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,400;0,700;1,400');

html,body{
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-weight: 400;
  user-select: none;

}
.bgBack {
  background-image: url("assets/traceBG.jpg");
  background-repeat: repeat;
  background-size: 100%;
  color: rgb(50, 50, 60);
}
h1 {
  font-weight: 700;
  font-family: 'Titillium Web', sans-serif;
  font-size: 38px;
}
h1.crisp {
  font-weight: 200;
  letter-spacing: 2px;
  font-family: 'Titillium Web', sans-serif;
  font-size: 38px;
}
h2 {
  font-weight: 600;
  font-family: 'Titillium Web', sans-serif;
  font-size: 28px;
}
h3 {
  font-weight: 400;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
}
h3.crisp{
  font-weight: 200;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
}
h4 {
  font-weight: 300;
  font-family: 'Titillium Web', sans-serif;
  font-style: oblique;
  font-size: 16px;
}

h4.crisp{
  font-weight: 200;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
}
h5 {
  font-weight: 300;
  font-family: 'Titillium Web', sans-serif;
  font-style: oblique;
  font-size: 12px;
}

h5.crisp{
  font-weight: 200;
  font-family: 'Titillium Web', sans-serif;
  font-size: 12px;
}

.FontText {
  font-weight: 300;
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
}

.btGradient {
  background: #141E30;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2e3238, #353d4e);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2e3238, #353d4e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #c0c0c0 !important;
}
.btGradientSec {
  background: #c0c0c0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #e0e0e0, #c0c0c0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #e0e0e0, #c0c0c0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.absolute {
  position: absolute;
}
.absolute.right {
  right: 0;
}

.styledContainer {
  padding: 5%;
  border-radius: 25px;
}

/* Improving the tables looks */
.v-data-table {
   white-space: nowrap;
}

/* SCROLL BAR*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {

  border-radius: 2px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #8f8f8f; 
  border-radius: 8px;
  width: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2c2c2c; 
  width: 8px;
}
</style>
<template>
<div>
  <Particles
      id="tsparticles"
      style="position: absolute; width: 100%; height: 100%"
      :options="{
          background: {
              color: {
                  value: 'transparent',
              }
          },
          fpsLimit: 30,
          interactivity: {
              detectsOn: 'canvas',
              events: {
                  onClick: {
                      enable: true,
                      mode: 'push'
                  },
                  onHover: {
                      enable: false,
                      mode: 'repulse'
                  },
                  resize: false
              },
              modes: {
                  bubble: {
                      distance: 600,
                      duration: 10,
                      opacity: 0.2,
                      size:64
                  },
                  push: {
                      quantity: 1
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4
                  }
              }
          },
          particles: {
              color: {
                  value: '#000'
              },
              links: {
                  color: '#444',
                  distance: 150,
                  enable: true,
                  opacity: .3,
                  width: .5
              },
              collisions: {
                  enable: false
              },
              move: {
                  direction: 'none',
                  enable: true,
                  outMode: 'none',
                  random: false,
                  speed: .2,
                  straight: true
              },
              number: {
                  density: {
                      enable: true,
                      value_area: 1200
                  },
                  value: 64
              },
              opacity: {
                  value: 0.25
              },
              shape: {
                  type: 'polygon',
                  options: {
                    polygon: [
                    {
                      sides: 3
                    }
                    ]
                  },
              },
              size: {
                  random: true,
                  value: 5
              }
          },
          detectRetina: true
      }"
    />
  <!-- Overlay until the Authorization -->
  <v-overlay :value="overlay" opacity=".98">
    <v-container>
      <v-row justify="center" >
        <h1 class="crisp">Please Wait</h1>
      </v-row>
      <v-row justify="center" class="mb-10">
        <h3 class="crisp">while we are connecting with our services</h3>
      </v-row>
      <v-row justify="center" class="mb-10">
        <v-progress-circular
          indeterminate
          width="1"
          size="64"
        ></v-progress-circular>
      </v-row>
      <v-row justify="center">
        <v-btn
          class="white--text btGradient"
          @click="overlay = !overlay"
        >
          Expedite Authentication
          <v-icon right>
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-overlay>
  <v-container class="styledContainer">
     <v-row class="text-center mt-6">
      <v-col cols="12" >
        <!-- Logo -->
        <img src="../assets/logo.png" class="mt-16 mb-0 logo" height="128" />
      </v-col>

      <v-col style="margin:0px; padding:0px" cols="12">
        <h1 style="font-size: 40px; margin:0px; padding:0px" class="m-0">
          {{ getAppInfo.name }}
        </h1>
        <h4 class=" mb-1">
          {{ getAppInfo.fullname }} 
        </h4>
      </v-col>

      <!-- QR Generation widget -->
      <v-col cols="12">
        <v-stepper style="border-radius: 20px; background: rgba(240,240,240,0.8); " class="mx-auto "
        max-width="1200"
        v-model="e1"
        >
          <v-stepper-header >
            <v-stepper-step

              :complete="e1 > 1"
              step="1"
            >
              Basic Information

            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
            >
              Additional Information
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step 
              :complete="e1 > 3" 
              step="3"
            >
              Review
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4">
              Result
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items >
            <!-- Basic Information -->
            <v-stepper-content step="1">
              <v-card
                class="mb-12 FontText"
                style="background: rgba(240,240,240,0.8);"
                elevation="0"
                height="480px"
              >
                <v-card-title class="FontText"><h2> Basic Information </h2></v-card-title>
                <v-card-subtitle style="text-align:left"><h4>Please provide all the required information</h4></v-card-subtitle>
                <v-card-text>
                  <v-form >
                    <v-row>
                      <v-col class="ml-0" cols="6">
                        <!-- Display Name -->
                        <v-text-field
                          v-model="displayName"
                          :rules="textfieldrules"
                          clearable
                          tabindex="1"
                          :counter="40"
                          filled
                          label="Display Name"
                          required
                        ></v-text-field>
                        <!-- Contact -->
                        <v-text-field
                          v-model="mobileNo"
                          :rules="contactnumberfieldrules"
                          hint="Only Numbers, '+', and '-' are Allowed"
                          clearable
                          tabindex="3"
                          :counter="18"
                          placeholder="Mobile - Contact No"
                          filled
                          label="Mobile - Contact No"
                          required
                        ></v-text-field>
                        <!-- Designation -->
                        <v-text-field
                          v-model="designation"
                          :rules="textfieldrules"
                          clearable
                          :counter="60"
                          tabindex="5"
                          filled
                          hint="Optional field"
                          label="Designation"
                        ></v-text-field>
                        <!-- Country -->
                      </v-col>
                      <v-col class="ml-0" cols="6">
                        <!-- Company Name -->
                         <v-text-field
                          v-model="companyName"
                          :rules="textfieldrules"
                          placeholder="Al‑Barrak Shipping Agencies Co. Ltd"
                          clearable
                          tabindex="1"
                          :counter="40"
                          filled
                          label="Company Name"
                          required
                        ></v-text-field>
                        <!-- Work # -->
                        <v-text-field
                          v-model="workNo"
                          :rules="contactnumberfieldrules"
                          hint="Only Numbers, '+', and '-' are Allowed"
                          clearable
                          tabindex="4"
                          :counter="20"
                          placeholder="Phone - Work #"
                          filled
                          label="Phone - Work #"
                          required
                        ></v-text-field>
                        <!-- Email-->
                        <v-text-field
                          v-model="emailOfficial"
                          :rules="emailfieldrules"
                          clearable
                          tabindex="6"
                          :counter="40"
                          filled
                          label="Official Email Address"
                          required
                        ></v-text-field>
                        <!-- Region -->
                      </v-col>

                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-select
                          :items="this.countryList"
                          tabindex="7"
                          item-text="name"
                          item-value="name"
                          v-model="selectedCountry"
                          return-object
                          label="Country"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :disabled="selectedCountry.length <= 0"
                          tabindex="8"
                          v-model="selectedCity"
                          :counter="40"
                          filled
                          clearable
                          label="State"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                  </v-form>
                </v-card-text>
              </v-card>


              <v-btn
                color="primary"
                tabindex="9"
                :disabled="!basicFormisValid"
                x-large
                @click="e1 = 2"
              >
                Continue
              </v-btn>

              <!-- <v-btn text>
                Cancel
              </v-btn> -->
            </v-stepper-content>

            <!-- Additional Information -->
            <v-stepper-content step="2">
              <v-card
                class="mb-12 FontText"
                elevation="0"
                style="background: rgba(240,240,240,0.8);"
                height="560px"
              >
              <v-card-title  class="FontText">Additional Information</v-card-title>
              <v-card-subtitle class="FontText" style="text-align: left;">Please provide all the required information</v-card-subtitle>
              
              <v-card-text >
                  <v-form >
                    <v-row>
                      <v-col class="ml-0" cols="6">
                        <!-- BaseID -->
                        <!-- <v-text-field
                          v-model="userID"
                          :counter="6"
                          filled
                          disabled
                          hint="This is for reference purpose only"
                          label="User Generated ID "
                        ></v-text-field> -->
                        <!-- BaseID -->
                        <v-text-field
                          v-model="fax"
                          :rules="contactnumberfieldrules"
                          hint="Only Numbers, '+', and '-' are Allowed"
                          :counter="20"
                          tabindex="2"
                          filled
                          label="Fax Number "
                        ></v-text-field>
                        <!-- Alt Mobile No -->
                        <v-text-field
                          v-model="altMobileNo"
                          :rules="contactnumberfieldrules"
                          hint="Only Numbers, '+', and '-' are Allowed"
                          tabindex="4"
                          :counter="18"
                          filled
                          label="Alternative Mobile - Contact No"
                        ></v-text-field>
                        <!-- Primary Address -->
                        <v-text-field
                          v-model="address"
                          :counter="100"
                          tabindex="6"
                          filled
                          hint="Primary Address"
                          label="Primary Address"
                        ></v-text-field>
                        <!-- Website -->
                        <v-text-field
                          v-model="website"
                          tabindex="9"
                          :counter="40"
                          filled
                          prefix="https://"
                          hint="Leave this field empty if the profile has no personal website, Absaco website will be used"
                          label="Website"
                        ></v-text-field>
                        <!-- tiktokHandle -->
                        <v-text-field
                          v-model="twitterHandle"
                          :rules="twitterfieldrules"
                          tabindex="10"
                          placeholder="https://www.twitter.com/[profile]"
                          filled
                          hint="Twitter Link - should only be a link"
                          label="Twitter Link"
                        ></v-text-field>
                      </v-col>
                      <v-col class="ml-0" cols="6">
                        <!-- Email Personal-->
                        <v-text-field
                          v-model="email"
                          :rules="emailfieldrules"
                          tabindex="3"
                          :counter="40"
                          filled
                          label="Personal Email Address"
                          required
                        ></v-text-field>
                        <!-- Sec Work No -->
                        <v-text-field
                          v-model="secWorkNo"
                          tabindex="5"
                          :counter="18"
                          :rules="contactnumberfieldrules"
                          filled
                          label="Secondary Work Number"
                        ></v-text-field>
                        <!-- Secondary Address -->
                        <v-text-field
                          v-model="secAddress"
                          :counter="100"
                          tabindex="7"
                          filled
                          hint="Secondary Address"
                          label="Secondary Address"
                        ></v-text-field>
                        <!-- TikTokHandle -->
                        <v-text-field
                          v-model="tiktokHandle"
                          :rules="tiktokrules"
                          tabindex="9"
                          filled
                          placeholder="https://www.tiktok.com/[profile]"
                          hint="Tiktok Link - should only be a link"
                          label="Tiktok Link"
                        ></v-text-field>
                        <!-- YoutubeHandle -->
                        <v-text-field
                          v-model="youtubeHandle"
                          :rules="youtuberules"
                          tabindex="11"
                          filled
                          placeholder="https://www.youtube.com/[profile]"
                          hint="YouTube Link - should only be a link"
                          label="YouTube Link"
                        ></v-text-field>
                        <!-- linkedInHandle -->
                        <!-- <v-text-field
                          v-model="linkedInHandle"
                          :rules="linkedInfieldrules"
                          tabindex="11"
                          filled
                          hint="LinkedIn Link - should only be a link"
                          label="LinkedIn Link"
                        ></v-text-field> -->
                      </v-col>
                      <v-col class="mt-0 pt-0" cols="4">
                        <!-- FacebookHandle -->
                        <v-text-field
                          v-model="facebookHandle"
                          :rules="fbfieldrules"
                          tabindex="12"
                          placeholder="https://www.facebook.com/[profile]"
                          filled
                          hint="Facebook Link - should only be a link"
                          label="Facebook Link"
                        ></v-text-field>
                      </v-col>
                      <v-col class="mt-0 pt-0" cols="4">
                        <!-- linkedInHandle -->
                        <v-text-field
                          v-model="linkedInHandle"
                          :rules="linkedInfieldrules"
                          tabindex="13"
                          filled
                          placeholder="https://www.linkedin.com/[profile]"
                          hint="LinkedIn Link - should only be a link"
                          label="LinkedIn Link"
                        ></v-text-field>
                      </v-col>
                        <!-- InstagramHandle -->
                      <v-col class="mt-0 pt-0" cols="4">
                        <v-text-field
                          v-model="instagramHandle"
                          :rules="instagramrules"
                          tabindex="14"
                          placeholder="https://www.instagram.com/[profile]"
                          filled
                          hint="Instagram Link - should only be a link"
                          label="Instagram Link"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                 
              </v-card-text>
              
              </v-card>

              <v-btn
                color="primary"
                tabindex="15"
                x-large
                :disabled="!additionalFormisValid"
                @click="e1 = 3"
              >
                Continue
              </v-btn>

              <v-btn
                text
                tabindex="16"
                x-large
                @click="e1 = 1">
                Back
              </v-btn>
            </v-stepper-content>

            <!-- Information -->
            <v-stepper-content step="3">
              <v-card
                class="mb-12 FontText"
                elevation="0"
                style="background: rgba(240,240,240,0.8);"
              >
              <v-card-title style="font-size:26px;" class="FontText">Review</v-card-title>
              <v-card-subtitle class="FontText" style="text-align: left; font-size:20px; font-weight: 500">Please Review the Data </v-card-subtitle>
              
              </v-card>
              <v-row no-gutters>
                <v-col>
                  <v-simple-table style="background: transparent">
                      <tbody>
                        <!-- Display Name -->
                        <tr>
                          <td >Display Name: </td>
                          <td>{{this.displayName}}</td>
                          <td >Company Name: </td>
                          <td>{{this.companyName}}</td>
                          <td >Twitter: </td>
                          <td><a>{{this.twitterHandle}}</a></td>
                        </tr>
                        <!-- Last Name  Designation-->
                        <!-- <tr>
                          <td>Last Name: </td>
                          <td>{{this.lastName}}</td>
                          <td>Designation Name: </td>
                          <td>{{this.designation}}</td>
                        </tr> -->
                        <!-- Mobile # + Alternative Mobile-->
                        <tr>
                          <td>Mobile #: </td>
                          <td>{{this.mobileNo}}</td>
                          <td>Alternative Mobile #: </td>
                          <td>{{this.altMobileNo}}</td>
                          <td >LinkedIN: </td>
                          <td><a>{{this.linkedInHandle}}</a></td>
                        </tr>
                        <!-- Alternative Mobile  Fax-->
                        <tr>
                          <td>workNo: </td>
                          <td>{{this.workNo}}</td>
                          <td>Fax #: </td>
                          <td>{{this.fax}}</td>
                          <td >Instagram: </td>
                          <td><a>{{this.instagramHandle}}</a></td>
                        </tr>
                        <!-- email + Official-->
                        <tr>
                          <td>Email Address: </td>
                          <td>{{this.email}}</td>
                          <td>Official Email Address: </td>
                          <td>{{this.emailOfficial}}</td>
                          <td >Facebook: </td>
                          <td><a>{{this.facebookHandle}}</a></td>
                        </tr>
                        <!-- Country + Region -->
                        <tr>
                          <td>Country: </td>
                          <td>{{this.selectedCountry.name}}</td>
                          <td>City/Region: </td>
                          <td>{{this.selectedCity}}</td>
                          <td >Tiktok: </td>
                          <td><a>{{this.tiktokHandle}}</a></td>
                        </tr>
                        <!-- Address + Sec -->
                        <tr>
                          <td>Address: </td>
                          <td>{{this.address}}</td>
                          <td>Secondary Address: </td>
                          <td>{{this.secAddress}}</td>
                          <td >Youtube: </td>
                          <td><a>{{this.youtubeHandle}}</a></td>
                        </tr>
                        <!-- website -->
                        <tr>
                          <td>website: </td>
                          <td>{{this.website}}</td>
                          <td >Secondary Work No: </td>
                          <td>{{this.secWorkNo}}</td>
                          <td >QR URL: </td>
                          <td><a>{{this.qrURL}}</a></td>
                        </tr>
                      </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>


              <v-btn
                color="primary"
                x-large
                class="btGradient"
                @click="
                RegisterUser(); e1 = 4;
                "
              >
                Submit
              </v-btn>

              <v-btn 
                text 
                x-large
                @click="e1 = 2">
                Back
              </v-btn>
            </v-stepper-content>

            <!-- Results -->
            <v-stepper-content step="4">
              <v-card
                class="mb-12 FontText"
                elevation="0"
                style="background: rgba(240,240,240,0.8);"
              >

              <v-card-title style="font-size:26px;" class="FontText">Result</v-card-title>
              <v-card-subtitle class="FontText" style="text-align: left; font-size:20px; font-weight: 500">Please check the QR-Code</v-card-subtitle>

                <v-card-text>
                  <a :href="downloadURL" download>
                    <vue-qr
                      id="baseQR"
                      elevation="2"
                      :size="256"
                      :text="qrURL"
                      :logoSrc="src"
                      :dotScale=".75"
                      colorDark="rgb(0, 71, 171)"
                      backgroundDimming="rgb(220,220,220)"
                      :gifBgSrc="gifBgSrc2"
                    ></vue-qr>
                  </a>
                  <div class="text-overline mb-4">
                    <v-btn
                      color="gray"
                      text
                      class="btGradientSec"
                      @click="UpdateQR()"
                    >
                      Generate QR
                    </v-btn>
                    <a :href="downloadURL" download>
                      <v-btn
                        color="gray"
                        text
                        class="btGradientSec"
                        @click="DownloadQR()"
                      >
                        Download QR
                      </v-btn>
                    </a>
                  </div>
                </v-card-text>

                <v-card-actions name="">
                  
                </v-card-actions>
              
              </v-card>

              

              <!-- <v-btn
                color="primary"
                x-large
                class="btGradient"
                @click="RegisterUser()"
              >
                Submit
              </v-btn> -->

              <v-btn
                text
                x-large
                class="btGradientSec"
                @click="ResetForm()"
              >
                Start Over
              </v-btn>
            </v-stepper-content>

          </v-stepper-items>
        </v-stepper>
      </v-col>

      

    </v-row>
  </v-container>
</div>
  
</template>

<script>
import VueQr from 'vue-qr'
import src from "../assets/logo.png";
import gifBgSrc2 from "../assets/map.gif";


export default {
  name: 'Home',

  data: () => ({
    src,
    gifBgSrc2,
    e1: 1,
    valid: true,
    // Overlay
    overlay: false,

    // QR
    
    downloadURL: '',

    // META APP
    textfieldrules: [
        v => !!v || 'This field is required',
    ],
    emailfieldrules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    passwordfieldrules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be more than 8 characters'
    ],
    contactnumberfieldrules: [
        //v => !!v || 'A valid Number is required',
        v => (!/[^\d+\-+$]/.test(v)) || 'No Character/Alphabets Allowed',
        //v => (!/[^0-9]/.test(v)) || 'No Character/Alphabets Allowed',
        v => (v && v.length >= 8) || 'Number must be more than 8 characters'
    ],
    twitterfieldrules: [
        v => (v.includes("https://twitter.com/") == true) || 'Should be a twitter Profile link'
    ],
    fbfieldrules: [
        v => (v.includes("https://www.facebook.com/") == true) || 'Should be a Facebook Profile link'
    ],
    linkedInfieldrules: [
        v => (v.includes("https://www.linkedin.com/") == true) || 'Should be a LinkedIN Profile link'
    ],
    instagramrules: [
        v => (v.includes("https://www.instagram.com") == true) || 'Should be a Instagram Profile link'
    ],
    tiktokrules: [
        v => (v.includes("https://www.tiktok.com/") == true) || 'Should be a Tiktok Profile link'
    ],
    youtuberules: [
        v => (v.includes("https://www.youtube.com") == true) || 'Should be a Youtube Profile link'
    ],
    //SelectUnitws
    countryList: [],
    cityList: [],

    //  firstName: 'Muhammad RADE',
    //  lastName: 'Mughal',
    // companyName: 'GripMatle',
    // displayName: 'DisplayName',
    // designation: 'CCS',
    // mobileNo: '031323622115',
    // altMobileNo: '031323622115',
    // workNo: '031323622115',
    // fax: '031323622115',
    // email: 'radeang@gmail.com',
    // emailOfficial: 'radeang@gmail.com',
    // address: 'House no 332512',
    // secAddress: 'House no 332515',
    // website: 'rade.com',
    // selectedCity: 'SilentCity',


    userID: '',
    // firstName: '',
    // lastName: '',
    companyName: 'ALBARRAK Group',
    displayName: '',
    designation: '',
    mobileNo: '',
    altMobileNo: '',
    workNo: '',
    fax: '',
    email: '',
    emailOfficial: '',
    address: '',
    secAddress: '',
    website: 'absaco.com',
    secWorkNo: '',
    linkedInHandle: '',
    instagramHandle: '',
    facebookHandle: '',
    twitterHandle: '',
    tiktokHandle: 'https://www.tiktok.com/@absaco_albarrakgroup',
    youtubeHandle: '',
    selectedCountry: '',
    selectedRegion: '',
    selectedCity: '',
    qrURL: '',
    trackingfieldrules: [
        v => !!v || 'A Number is required',
    ],

  }),
  components: {
    VueQr
  },
  methods: {
    Practing() {
      this.valid = true;

      this.$store.dispatch('setlastContainerNumber', {searchNumber: this.trackingID, searchType: this.NumberType}).then(() => {
        // Getting the access code
        setTimeout(() => {
          this.valid = false;
          //Setting up the routes
          let AccessURL = 'https://accounts.zoho.com/oauth/v2/auth?client_id='+ this.getAppAuth.ClientID +'&response_type=token&scope=ZohoCreator.report.READ&redirect_uri=http://localhost:8080/search';
          // this.$router.push(AccessURL)
          window.location.href = AccessURL;
        }, 1000)
      })

      this.valid = false;
      // setTimeout(() => {
      //   this.valid = false;
      //   //Setting up the routes
      //   let AccessURL = 'https://accounts.zoho.com/oauth/v2/auth?client_id='+ this.getAppAuth.clientID +'&response_type=token&scope=ZohoCreator.report.READ&redirect_uri=http://localhost:8080/search';
      //   this.$router.push(AccessURL)
      // }, 1000)

    },

    // Random Number Generator
    AlphaNumericNumber() {
      let characters = 'EFGHIJKLMNO0123456789';
      let result = "";
      let charactersLength = characters.length;
      for ( var i = 0; i < 6 ; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result
    },
    // Reset
    ResetForm() {
      //Reset UserID
      this.UserID = this.AlphaNumericNumber();
      // Main Page
      this.e1 = 1;
    },

    // Registering User
    RegisterUser() {
      this.overlay = true;
      this.valid = true;
      
      // Dispatching the main Protocol
      let payload = {
        displayName: this.displayName,
        linkedInHandle: this.linkedInHandle,
        facebookHandle: this.facebookHandle,
        tiktokHandle: this.tiktokHandle,
        youtubeHandle: this.youtubeHandle,
        twitterHandle: this.twitterHandle,
        instagramHandle: this.instagramHandle,
        // firstName: this.firstName,

        companyName: this.companyName,
        fax: this.fax,
        // lastName: this.lastName,
        email: this.email,
        emailOfficial: this.emailOfficial,
        designation: this.designation,
        mobileNo: this.mobileNo,
        altMobileNo: this.altMobileNo,
        workNo: this.workNo,
        secWorkNo: this.secWorkNo,
        address: this.address,
        secAddress: this.secAddress,
        website: this.website,
        userID: this.userID,
        country: this.selectedCountry.name,
        city: this.selectedCity,
        qrImage: this.getQRImage
      }

      this.$store.dispatch('PushUserRegistrationData', payload).then(() => {
        console.log("Initial value", payload)
        setTimeout(() => {
          this.overlay = false;
        }, 1000)
      })
    },
    UpdateQR() {
      //Getting the getters
      this.qrURL = this.getAppInfo.domain + '/search?user=' + this.userID;
      this.downloadURL = document.getElementById('baseQR').src;

      this.VCFQRupdate ? false : true ;
    },
    DownloadQR() {
      //Getting the DOM
      this.downloadURL = document.getElementById('baseQR').src;
      let image = document.getElementById('baseQR');
      console.log("image value", image)
      // let filename = "QR.png";
    },

    // Submission of the search Number
    NumberSubmit() {
      // Dertermining the Selected Number
      console.log("Getting the Number Type",this.GetSubmittedNumberType());



      // REDIRECTING TO SEARCH PAGE
      this.valid = true;

      this.$store.dispatch('setsubmittedContainerInfo', {
        trackingID: this.trackingID,
        NumberTypeIndex: this.GetSubmittedNumberType()
      })

      setTimeout(() => {
        this.valid = false;
        //Setting up the routes
        this.$router.push("search/")
      }, 1000)

      //Consoling the values

      

      //Getting the Authorization from the state
      //Sending the instructions to the state
      // let pageId = String(this.$route.params.id);
      //this.$store.dispatch('GetAuthentication')
      // this.$store.dispatch('setContainerInformation', {
      //   id: pageId
      // })
    },

    // -----------------------------
    // UTILITIES
    // Submission of the search Number
    GetSubmittedNumberType() {
      // Dertermining the Selected Number
      let SubmittedNumberType = 0; // Default is Container_Number

      // Iterating the Available Number Types
      for (let i = 0; i < this.trackingNumberTypes.length; i++) {
        const element = this.trackingNumberTypes[i];

        // Matching and setting the get Number
        if (this.NumberType == element) {
          SubmittedNumberType = i;
        }
      }
      return SubmittedNumberType
      //Consoling the values
    },
    checkUserLogin() {
      // Checking if the user is already signed In
      this.$store.dispatch('userSession').then(() => {
        // Check is user is LOGGED IN?
        setTimeout(() => {
          if (this.getUserInfo === 0 || this.getUserInfo == null || this.getUserInfo == undefined){
            //if user is null then the app will notify after 2 seconds
            if (this.$route.name != 'Login' && this.$route.name != 'Registration') {
              setTimeout(() => {
                console.log("YOU ARE NOT LOGGED IN");
                this.$router.push({'name': 'Login'})
                this.OpenDialog({active: true, heading: 'The Session is not valid', description: 'Please login again with correct credentials'})
              }, 1000)
            }
          }
        }, 2000)
      });
    },
  },
  computed: {
    getUserInfo() {
      return this.$store.getters.userinfo;
    },
    getAppInfo(){
      return this.$store.getters.appinfo;
    },
    getAppAuth(){
      return this.$store.getters.appAuth;
    },
    getQRImage(){
      let downloadURL = document.getElementById('baseQR').src; 
      return downloadURL;
    },
    basicFormisValid() {
      return (
        // this.firstName &&
        // this.lastName &&
        this.companyName &&
        this.mobileNo &&
        this.emailOfficial &&
        this.designation &&
        this.workNo &&
        this.selectedCountry 
      );
    },
    additionalFormisValid() {
      return (
        true
        // this.fax &&
        // this.altMobileNo &&
        // this.email &&
        // this.address &&
        // this.secAddress &&
        // this.website 
      );
    }
  },
  created() {
    // Random number Generator
    this.userID = this.AlphaNumericNumber();
    setTimeout(() => {
      this.qrURL = this.getAppInfo.domain + '/search?user=' + this.userID;
      this.countryList = require("../assets/data/countries.json");
      // console.log(this.countryList)
    }, 2000)
    // Check the User Credentials
    setTimeout(() => {
      // Setting the Dialog
      let dialog = {
          active: true,
          heading: 'You are not Allowed',
          description: "Please get permission to active this feature, Your Existing Role is: " + this.getUserInfo.authRole
        }
      //console.log('Watch Kicked Object == ', Object.keys(this.getUserInfo))
      if ((this.getUserInfo == null && this.getUserInfo  == undefined && this.getUserInfo  == {}) || (Object.keys(this.getUserInfo).length == 0)) {
        console.log('Not logged in Moving to Login Page!')
        this.$router.push({'name': 'Login'})  // ACTIVATION
        //this.checkUserLogin();
      } else if (Object.keys(this.getUserInfo) != 0) {
        // Checking the Role
        console.log("Checking...", this.getUserInfo.authRole)
        switch (this.getUserInfo.authRole) {
          case "Admin":
            console.log("Role is Admin");
            break;

          case "Data Entry":
            console.log("Role is Data Entry");
            break;

          case "Data Update":
            console.log("Role is Data Update");
            this.$store.dispatch("setDialog", dialog);
            this.$router.push({'name': 'Login'})
            break; 
        
          default:
            break;
        }
      }
    }, 4500)
  },
  watch: {
    // selectedCountry() {
    //   this.$emit("country", this.selectedCountry);
    //   this.cityList = [];
    //   for (const country of this.countryList) {
    //     if (this.selectedCountry.name== country.name) {
    //       country.states.forEach(city => {
    //         this.cityList.push(city);
    //       });
    //     }
    //   }
    // },
    // selectedCity() {
    //   this.$emit("city", this.selectedCity);
    // },
    // Check the User Credentials
    getUserInfo () {
      if ((this.getUserInfo == null && this.getUserInfo  == undefined && this.getUserInfo  == {}) || (Object.keys(this.getUserInfo).length == 0)) {
        console.log('Watch Kicked Batch')
        //this.checkUserLogin();
      } 
    },
  },
}
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}
.styledContainer {
  border-radius: 25px;
}


#tsparticles {
  position: absolute;
  width: 100%;
  z-index: -5;
}
a {
  text-decoration: none;
}
img .logo {
  z-index: 2;
}
.boxColor {
   background: rgba(230,230,230,0.75); 
}

.countries-cities {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
}
.select-box {
  height: 40px;
  min-width: 300px;
  background: #fff;
  border: 1px solid #e9e9e9;
  position: relative;
}
select {
  background: none;
  border: none;
  outline: none;
  padding-left: 7px;
  padding-right: 65px;
  appearance: none;
  width: 100%;
  height: 100%;
}
svg {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  background: darken(#fff, 3%);
  pointer-events: none;
  box-sizing: border-box;
  padding: 5px;
}
path {
  fill: rgba(black, .7);
}
</style>
<template>
<div>
  <Particles
      id="tsparticles"
      
      :options="{
          background: {
              color: {
                  value: 'transparent',
              }
          },
          fpsLimit: 30,
          interactivity: {
              detectsOn: 'canvas',
              events: {
                  onClick: {
                      enable: true,
                      mode: 'push'
                  },
                  onHover: {
                      enable: false,
                      mode: 'repulse'
                  },
                  resize: false
              },
              modes: {
                  bubble: {
                      distance: 600,
                      duration: 10,
                      opacity: 0.8,
                      size:32
                  },
                  push: {
                      quantity: 1
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4
                  }
              }
          },
          particles: {
              color: {
                  value: '#000'
              },
              links: {
                  color: '#444',
                  distance: 150,
                  enable: true,
                  opacity: .3,
                  width: .5
              },
              collisions: {
                  enable: false
              },
              move: {
                  direction: 'none',
                  enable: true,
                  outMode: 'none',
                  random: false,
                  speed: .2,
                  straight: true
              },
              number: {
                  density: {
                      enable: true,
                      value_area: 1200
                  },
                  value: 64
              },
              opacity: {
                  value: 0.25
              },
              shape: {
                  type: 'polygon',
                  options: {
                    polygon: [
                    {
                      sides: 3
                    }
                    ]
                  },
              },
              size: {
                  random: true,
                  value: 5
              }
          },
          detectRetina: true
      }"
    />
  <!-- CONTEXT -->
  <v-container>
     <v-row class="text-center">
       <v-col cols="12">
         <v-row>
           <v-col cols="4"></v-col>
            <v-col cols="4">
              <!-- Logo -->
              <img src="../assets/logo.png" class="mt-15 mb-0 logo" height="128" />
              <h1 class=" mb-1">
                {{ getAppInfo.name }}
              </h1>
              <h4 class=" mb-1">
                {{ getAppInfo.fullname }} 
              </h4>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
       </v-col>

      <!-- Tracking widget -->
      <v-col class="mb-4" cols="12">
        <v-card
          class="mx-auto"
          style="border-radius: 25px; background: rgba(240,240,240,0.75);"
          elevation="20"
          max-width="400"
          :loading="valid"
        >

          <v-card-title class="mb-3" justify="center">
            <!-- Tracking ID: -->
            <h2 >Login</h2>
          </v-card-title>

          <!-- <v-card-subtitle class="pb-0">
            SUB-TITLE
          </v-card-subtitle> -->

          <v-card-text class="text--primary">
            <v-form >
              <v-row>
                <!-- EMAIL -->
                <v-col class="ml-0" cols="12">
                  <v-text-field
                    v-model="email"
                    :rules="emailfieldrules"
                    :counter="40"
                    placeholder="xyz@xyz.com"
                    filled
                    name="email"
                    label="Email"
                    clearable
                    required
                    hint="Your Registered Email"
                  ></v-text-field>
                  <!-- password -->
                  <v-text-field
                    v-model="password"
                    :rules="passwordfieldrules"
                    type="password"
                    name="password"
                    clearable
                    filled
                    minlength="8"
                    label="Password"
                    required
                    hint="Your Password"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
            <!-- buttons -->
            <v-list-item class="grow" >
              
              <v-col cols="3">
              </v-col>

              <v-col cols="6">
                <v-row
                justify="center"
                >
                  <v-btn
                    class="ml-2 btGradientSec"
                    elevation="15"
                    block
                    v-on:click="Practing"
                  >
                    Login
                  </v-btn>
                </v-row>
              </v-col>
            </v-list-item>
          </v-card-text>
        
          <v-card-actions >
            <v-row justify="end">
              <v-btn
              class="mr-3 mb-3 btGradientSec"
              elevation="1"
              x-small
              v-on:click="Registeration"
              >
                Registration
              </v-btn>
              <v-btn
              class="mr-3 mb-3 btGradientSec"
              elevation="1"
              x-small
              @click="OpenDialog({
                heading: 'Please contact the support!', // HEADING
                description: 'Forgetting you password can be fixed by contact the support and get a new registration access.'  // DESCRIPTION
              })"
              >
                Forgot Password?
              </v-btn>
            </v-row>
          </v-card-actions>
          
        </v-card>
      </v-col>

      <v-col
        class="mb-1"
        cols="12"
      >
        <h3 class="headline font-weight-bold mb-1">
        </h3>

        <p>
          <span style="color: #999; font-weight: bolder"> 
          App Version
          {{ getAppInfo.version }}
          </span>
        </p>



      </v-col>
      

    </v-row>
  </v-container>
</div>
  
</template>

<script>
// import App from '../App.vue'

  export default {
    name: 'Home',

    data: () => ({
    valid: false,

    // META APP
    email: '',
    password: '',
    emailfieldrules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    passwordfieldrules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be more than 8 characters'
    ],

    }),
    methods: {
      // GUI
      // OpenDialog(payload) {
      //   // Setting the Dialog State
      //   this.$emit('dialog', payload)
      // },
      // LOGIC
      Registeration() {
        // Setting the router to the Registeration screen
        this.$router.push({'name': 'Registration'})
      },
      Practing() {
        console.log("Log Infront");
        // console.log(this.NumberType);

        let data = {
          email: this.email,
          password: this.password
        }

        this.$store.dispatch('userSignIn', data).then((res) => {
          console.log("Check User",res);

          // Checking the Role
          switch (this.getUserInfo.authRole) {
            case "Admin":
              console.log("Role is Admin");
              this.$router.push({'name': 'Home'})
              break;

            case "Data Entry":
              console.log("Role is Data Entry");
              this.$router.push({'name': 'Home'})
              break;

            case "Data Update":
              console.log("Role is Data Update");
              this.$router.push({'name': 'Manage'})
              break; 
          
            default:
              break;
          }
          
        });

        this.valid = true;
        // setTimeout(() => {
        //   this.valid = false;
        //   //Setting up the routes
        //   let AccessURL = 'https://accounts.zoho.com/oauth/v2/auth?client_id='+ this.getAppAuth.clientID +'&response_type=token&scope=ZohoCreator.report.READ&redirect_uri=http://localhost:8080/search';
        //   this.$router.push(AccessURL)
        // }, 1000)
      },
      //Login
      checkUserLogin() {
        // Checking if the user is already signed In
        this.$store.dispatch('userSession').then(() => {
          // Check is user is LOGGED IN?
          setTimeout(() => {
            if (this.getUserInfo === 0 || this.getUserInfo == null || this.getUserInfo == undefined){
              //if user is null then the app will notify after 2 seconds
              if (this.$route.name != 'Login' && this.$route.name != 'Registration') {
                setTimeout(() => {
                  console.log("YOU ARE NOT LOGGED IN");
                  this.$router.push({'name': 'Login'})
                  this.OpenDialog({heading: 'The Session is not valid', description: 'Please login again with correct credentials'})
                }, 1000)
              }
            }
          }, 2000)
        });
      },
      // GUI
      OpenDialog(payload) {
        //
        // console.log(payload);
        // Setting the Dialog State
        let dialogText = {
          heading: payload.heading,
          description: payload.description
        }
        this.$store.dispatch('setDialog', dialogText)

        // Setting the dialog opened
        this.dialog = true
      },
    },
    computed: {
      getAppInfo(){
        return this.$store.getters.appinfo;
      },
      getAppAuth(){
        return this.$store.getters.appAuth;
      },
      getUserInfo(){
      return this.$store.getters.userinfo;
      },
      getUser(){
      return this.$store.getters.user;
    }
    },
    watch: {
      // On User Infomation Verify
      // Checking the Role
      getUserInfo (){
        console.log("Checking...", this.getUserInfo.authRole)
        switch (this.getUserInfo.authRole) {
          case "Admin":
            console.log("Role is Admin");
            this.$router.push({'name': 'Home'})
            break;

          case "Data Entry":
            console.log("Role is Data Entry");
            this.$router.push({'name': 'Home'})
            break;

          case "Data Update":
            console.log("Role is Data Update");
            this.$router.push({'name': 'Manage'})
            break; 
        
          default:
            break;
        }
      },
      // SetLoading (){
      //   if (this.getMainLoading == true) {
      //     console.log('Watch Kicked')
      //     this.checkUserLogin();
      //   } 
      // },
    },
    created() {
      // Redirecting the User to the right Page
      // Checking the Role
      
    }
  }
  
</script>

<style scoped>
/*Disabling the Scrolls*/
body {
  overflow: hidden;
}

#tsparticles {
  position: absolute;
  width: 100%;
  z-index: 0;
}
img .logo {
  z-index: 2;
}
.boxColor {
   background: rgba(230,230,230,0.6); 
}
</style>
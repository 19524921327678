<template>
<div>

</div>
  
</template>

<script>

  export default {
    name: 'getQRImage',

    data: () => ({
    valid: false,

    })
  }
  
</script>

// importing VUE X
import Vue from 'vue';
import Vuex from 'vuex';
// import dependency to handle HTTP request to our back end
import axios from 'axios'
// Setting up the cookie

// importing Firebase
import { initializeApp } from 'firebase/app';
import {getAuth, setPersistence, signOut, browserSessionPersistence, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import {getDatabase, ref, update } from "firebase/database";
import { getStorage, ref as Iref, uploadString, getDownloadURL  } from "firebase/storage";
import { getFirestore, doc, setDoc, getDocs, collection, deleteDoc, getDoc } from "firebase/firestore"; 
//import { getStorage, ref as Iref, uploadString  } from "firebase/storage";

Vue.use(Vuex);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFB33NGR9sXmcLkylSGUP_V3XHGVAqFr8",
  authDomain: "qrabsa-c54b0.firebaseapp.com",
  databaseURL: "https://qrabsa-c54b0-default-rtdb.firebaseio.com",
  projectId: "qrabsa-c54b0",
  storageBucket: "qrabsa-c54b0.appspot.com",
  messagingSenderId: "770940410626",
  appId: "1:770940410626:web:92612bd496c89be877801b"
};

const app = initializeApp(firebaseConfig)


// Storage
export const store = new Vuex.Store({
  state: {
    // App Loading Stats
    userError: false,
    loadingState:{
      mainLoading: false,
      compressorLoadingStats: false,
    },
    successFlag: false,
    successMsg: 'Operation Successful',
    // Services Status
    services:{

    },
    DialogText: {
      active: false,
      heading: 'Please Login',
      description: 'Please Login Description'
    },
    SnackBarText: 'Please Login',
    // Application meta Information
    appinfo : {
      name : 'ABSA',
      fullname: 'ABSACO QR Code Generator',
      author : 'radean',
      regKey: 'QRAbsa1542',
      authorEmail : 'radeanf@gmail.com',
      developer : 'radean',
      domain: "https://qr.absaco.com",
      downloadServer: "https://storage.absaco.com",
      company : 'Astrone',
      version : 'v 1.19',
      status : true,
      startDate: '03-07-2022',
      endDate: '03-12-2022',
      mode: '',
      broadcast: true,
      subscription: true
    },

    // App Authentication
    appAuth: {
      ClientID: '1000.H5ALTHIR2E0M316C2D89PM59LZUJKR',
      ClientSecret: '3aadda769ceddd6e06b8a2b78e30b4237c6cc96a2e',
      AuthToken: '',
      RefreshToken: '1000.e7e7537159ad66a3864b137885ff0901.0ababb5923ee6ecf466b8d5e8ca7acaf',
      accessCode: ''
    },
    submittedContainerInfo: {
      trackingID: '',
      NumberTypeIndex: ''
    },
    // App Main Data
    // RegisteredUser
    RegisteredUser: {

    },
    AllRegisteredUser: [],
    //Setup Redirector
    redirect: {
      active: false,
      routeName: 'Login'
    },

    UpdateUserData: {},

    // Search User ID
    SearchUserID: '3JIG',
    userinfo: {},
    user: null,
  },
  mutations: {
    // ------
    // ------
    // GUI
    setAppHeader(state, payload){
      state.app.header.name = payload.name;
      state.app.header.location = payload.location;
    },
    setUser (state, payload){
      state.user = payload;
    },
    setUserInfo (state, payload){
      state.userinfo = payload;
    },
    'SET_MAIN_LOADING'(state, payload){
      state.loadingState.mainLoading = payload;
    },
    'SET_USER_INFORMATION'(state, payload){
      state.loadingState.userinfo = payload;
    },
    'SET_REDIRECTOR'(state, payload){
      state.redirect = payload;
    },
    'SET_DIALOGTEXT'(state, payload){
      state.DialogText.active = payload.active;
      state.DialogText.heading = payload.heading;
      state.DialogText.description = payload.description;
    },
    'SET_SNACKBARTEXT'(state, payload){
      state.SnackBarText = payload;
    },
    // ------
    // ------
    // LOGIC
    'SET_CONTAINER_NUMBER' (state, payload) {
      state.containerinfo.number = payload;
    },
    'SET_ACCESS_CODE' (state, payload) {
      state.appAuth.accessCode = payload;
    },
    'SET_SUBMITTED_CONTAINER_INFO' (state, payload) {
      state.submittedContainerInfo.trackingID = payload.trackingID;
      state.submittedContainerInfo.NumberTypeIndex = payload.NumberTypeIndex;
    },
    'SET_CONTAINER_INFORMATION' (state, payload) {
      // MUTATING THE VARIABLE


      state.containerinfo = payload;
    },
    'SET_REGISTERED_USER_INFORMATION' (state, payload) {
      // MUTATING THE VARIABLE
      state.RegisteredUser = payload;
    },
    'SET_UPDATE_USER_INFO' (state, payload) {
      // MUTATING THE VARIABLE
      state.UpdateUserData = payload;
    },
    'SET_ALL_REGISTERED_USER_INFORMATION' (state, payload) {
      // MUTATING THE VARIABLE
      state.AllRegisteredUser = payload;
    }
  },
  actions: {
    // GUI
    // ---------
    setMainLoading({commit}, payload){
      commit('SET_MAIN_LOADING', payload);
    },
    setDialog({commit}, payload){
      commit('SET_DIALOGTEXT', payload);
    },
    setSnackBarText({commit}, payload){
      commit('SET_SNACKBARTEXT', payload);
    },
    setRedirector({commit}, payload){
      commit('SET_REDIRECTOR', payload);
    },
    // USERS
    // USER AUTHENTICATION
    userSignUp({commit}, payload){
      // let userID;
      const auth = getAuth() 
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      createUserWithEmailAndPassword(auth, payload.email, payload.password)
        .then((user) => {
          console.log('User Information', user.user.uid)
          commit('SET_MAIN_LOADING', true);
          const userInfo = {
            uniqueId: user.user.uid,
            authRole: payload.authRole,
            password: payload.password,
            email: payload.email,
            fullname: payload.fullname
          };

          setDoc(doc(db, "auth/", user.user.uid), {// Setting the data
          //set(ref(db, 'auth/' + userInfo.uniqueId), {
            uniqueId: userInfo.uniqueId,
            password: userInfo.password,
            email: userInfo.email,
            fullname: userInfo.fullname,
            authRole: userInfo.authRole,
            access_token: '',
            lastSearchNumber: '',
            lastSearchType: ''
          });
          commit('SET_MAIN_LOADING', false);
        })
    },
    userSignIn({commit, dispatch}, payload){
    const auth = getAuth();
    signInWithEmailAndPassword(auth, payload.email, payload.password)
      .then(onAuthStateChanged(auth, (appUser) => {
        commit('setUser', appUser)
        // setting the persistent values
        setPersistence(auth, browserSessionPersistence)

      })).catch(
        error => {
          if (!error)
          {
            dispatch('userSession');
          }
          console.log(error);

          let dialog = {
            active: true,
            heading: 'Wrong Credentials',
            description: "Email or Password is wrong, Please Try Again."
          }
          commit('SET_DIALOGTEXT', dialog);
          // commit('SET_REDIRECTOR', {active: true, routeName: 'Login'});
      })
    },
    // User Session Check
    userSession({dispatch, commit}){
      // Checking Firebase user
      const auth = getAuth();
      onAuthStateChanged(auth, (appUser) => {
        if(appUser) {
          // setting Authorization
          commit('setUser', appUser);
          dispatch('subUserInfo');
        }else{
          commit('setUser', null);
        }
      });
    },
    // user information update
    subUserInfo({commit, getters}){
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      // Setting Loading
      commit('SET_MAIN_LOADING', true);
      // setting user information
      //onValue(ref(db, 'auth/' + getters.user.uid + '/'), (snapshot) => {
      getDoc(doc(db, "auth", getters.user.uid)).then((snapshot) => {
        const data = snapshot.data();
        // console.log(data);
        commit('setUserInfo', data);

        commit('SET_MAIN_LOADING', false);
      })
    },
    // user Log out
    userSignOut({commit}){
      const auth = getAuth();
      commit('SET_MAIN_LOADING', true);
      signOut(auth).then(() =>{
        // setting App Header
        commit('setUserInfo', null);
        commit('setUser', null);
        commit('SET_MAIN_LOADING', false);
      });
    },


    // LOGIC
    // -------------
    setContainerNumber({commit}, payload) {
      commit('SET_CONTAINER_NUMBER', payload);
    },
    setsubmittedContainerInfo({commit}, payload) {
      commit('SET_CONTAINER_NUMBER', payload);
    },
    GetAuthentication() {
      // Getting Registration Authorization 
      console.log("Getting Authorization Begin...")

      // Getting the AccessCode
      let AppAuth = this.state.appAuth;

      console.log("Auth Object", AppAuth)

      let data = new FormData();
      data = {
        'criteria': 'Sales_Order_Number=="3200097152"' 
      };

      axios({
        method: 'post',
        
        url: 'https://creator.zoho.com/api/v2/albarrakshippingcoltd75/digital-space/report/Vessel_Planner_2_0_Report',
        headers: {
          // "Access-Control-Allow-Origin" : "http://localhost:8080/search",
          // 'Access-Control-Allow-Methods': 'POST',
          // 'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": 'Zoho-oauthtoken ' + AppAuth.accessCode,
        },
        data: data
        }).then((response) => {
        console.log("Getting Authentication",JSON.stringify(response.data))
      })
    },
    setContainerInformation({commit,}, payload) {
      // Getting the container details from the server
      let data = new FormData();
      console.log("Setting Tracking ID (From the STATE): ", payload.id)
      data.append('id', payload.id);
      axios({
        method: 'post',
        url: 'https://getabsa.techerupt.com',
        headers: {
          // "Access-Control-Allow-Origin" : "*",
          // 'Access-Control-Allow-Methods': 'POST',
          'Content-Type': 'multipart/form-data',
        },
        data: data
        }).then((response) => {
        console.log(JSON.stringify(response.data))
        // After getting all the data from the Server
        // COMMITING MUTATION
        commit('SET_CONTAINER_INFORMATION', response.data);
          
        })
    },
    setAccessCode({commit, getters}, payload) {
      // Getting the container details from the server
      // Setting Loading
      commit('SET_MAIN_LOADING', true);
      if (getters.user != null) {
        // console.log('Got the hit to store the access codes', payload);
        // Setting the current user
        let user = getters.user;
        const db = getDatabase();
        // getting the current user from the server
        update(ref(db, 'users/' + user.uid), {
          access_token: payload
        }).catch(
          error => {
            console.log(error);
        })
        commit('SET_ACCESS_CODE', payload);
        // Setting Loading
        commit('SET_MAIN_LOADING', false);
      }

      // Setting Loading
      commit('SET_MAIN_LOADING', false);
      // this.dispatch('GetAuthentication');
    },
    // Pushing User Data
    PushUserRegistrationData({commit}, payload) {
      // Getting the container details from the server
      let RData = payload;
      const storage = getStorage();
      const storageRef = Iref(storage, 'users/' + RData.displayName + '.gif');
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      // Image
      const qrimagesrc = RData.qrImage;
      // 'file' comes from the Blob or File API
      uploadString(storageRef, qrimagesrc, 'data_url').then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot);
          // getting the current user from the server
        setDoc(doc(db, "users", payload.userID), {// Setting the data
        //set(ref(db, 'users/' + RData.userID), {
          displayName: RData.displayName,
          qrimageURL: snapshot.metadata.fullPath,
          linkedInHandle: RData.linkedInHandle,
          facebookHandle: RData.facebookHandle,
          tiktokHandle: RData.tiktokHandle,
          youtubeHandle: RData.youtubeHandle,
          twitterHandle: RData.twitterHandle,
          instagramHandle: RData.instagramHandle,
          companyName: RData.companyName,
          secWorkNo: RData.secWorkNo,
          // firstName: RData.firstName,
          // lastName: RData.lastName,
          fax: RData.fax,
          email: RData.email,
          emailOfficial: RData.emailOfficial,
          designation: RData.designation,
          mobileNo: RData.mobileNo,
          altMobileNo: RData.altMobileNo,
          workNo: RData.workNo,
          address: RData.address,
          secAddress: RData.secAddress,
          website: RData.website,
          country: RData.country,
          userID: RData.userID,
          city: RData.city,
          timeStamp: Date.now()
        }).then(() => {
          console.log("Sending File", RData.displayName)
          // Sending QR Image to FileVault - PHP Server
          let data = new FormData();
          // Sending DisplayName to create pretty URL with like absaqr.radean.xyz/{displayName}.gif
          // Sending should be redirected to the storage.absaco.com
          data.append('displayName', '"' + RData.displayName + '"');
          // Sending image to upload to PHP Server
          data.append('qrImageFile', qrimagesrc);
          console.log("Image Data: ", data);
          axios({
            method: 'post',
            url: 'http://storage.absaco.com',
            headers: {
              // "Access-Control-Allow-Origin" : "*",
              // 'Access-Control-Allow-Methods': 'POST',
              'Content-Type': 'multipart/form-data',
            },
            data: data
          }).then((response) => {
            console.log(JSON.stringify(response.data))
          })
        }).catch(
          error => {
            console.log(error);
        })
      });
      
      

      
      // }
      commit('SET_REGISTERED_USER_INFORMATION', payload);

      // this.dispatch('GetAuthentication');
    },
    // Pushing QR Based on DisplayName
    PushOnlyQRBasedonDisplayName({commit}, payload) {
      // Getting the container details from the server
      let RData = payload;
      const storage = getStorage();
      const storageRef = Iref(storage, 'users/' + RData.displayName + '.gif');
      // Initialize Cloud Firestore and get a reference to the service
      // const db = getFirestore(app);
      // Image
      const qrimagesrc = RData.qrImage;
      // 'file' comes from the Blob or File API
      uploadString(storageRef, qrimagesrc, 'data_url').then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot);
          // getting the current user from the server
          console.log("Sending File", RData.displayName)
          // Sending QR Image to FileVault - PHP Server
          let data = new FormData();
          // Sending DisplayName to create pretty URL with like absaqr.radean.xyz/{displayName}.gif
          // Sending should be redirected to the storage.absaco.com
          data.append('displayName', '"' + RData.displayName + '"');
          // Sending image to upload to PHP Server
          data.append('qrImageFile', qrimagesrc);
          console.log("Image Data: ", data);
          axios({
            method: 'post',
            url: 'https://storage.absaco.com',
            headers: {
              // "Access-Control-Allow-Origin" : "*",
              // 'Access-Control-Allow-Methods': 'POST',
              'Content-Type': 'multipart/form-data',
            },
            data: data
          }).then((response) => {
            console.log(JSON.stringify(response.data))
          })
      });
      
      

      
      // }
      commit('SET_REGISTERED_USER_INFORMATION', payload);

      // this.dispatch('GetAuthentication');
    },
    // Pushing Specific User Data
    UpdateUserData({commit}, payload) {
      commit('SET_MAIN_LOADING', true);
      // Getting the container details from the server
      let RData = {};
      for (var obj in payload){
        if(payload[obj] == undefined || payload[obj] == null || payload[obj] == 0)
        {
          payload[obj] = "-"
        }
        
        RData[obj] = payload[obj];
      }
      console.log(RData)

      //let RData = payload;

      console.log("From State", RData);
      commit('SET_UPDATE_USER_INFO', RData);
      // const storage = getStorage();
      // const storageRef = Iref(storage, 'users/' + RData.displayName + '.gif');
      //const db = getDatabase();
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      setDoc(doc(db, "users", payload.userID), {// Setting the data
      //set(ref(db, 'users/' + RData.userID), {
        displayName: RData.displayName,
        linkedInHandle: RData.linkedInHandle,
        facebookHandle: RData.facebookHandle,
        tiktokHandle: RData.tiktokHandle,
        youtubeHandle: RData.youtubeHandle,
        twitterHandle: RData.twitterHandle,
        instagramHandle: RData.instagramHandle,
        companyName: RData.companyName,
        secWorkNo: RData.secWorkNo,
        // firstName: RData.firstName,
        // lastName: RData.lastName,
        fax: RData.fax,
        email: RData.email,
        emailOfficial: RData.emailOfficial,
        designation: RData.designation,
        mobileNo: RData.mobileNo,
        altMobileNo: RData.altMobileNo,
        workNo: RData.workNo,
        address: RData.address,
        secAddress: RData.secAddress,
        website: RData.website,
        country: RData.country,
        userID: RData.userID,
        city: RData.city,
      })
      commit('SET_MAIN_LOADING', false);
      
      // }
      //commit('SET_REGISTERED_USER_INFORMATION', payload);

      // this.dispatch('GetAuthentication');
    },
    // Delete Specific User Data
    DeleteUserByID({commit}, payload) {
      commit('SET_MAIN_LOADING', true);
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      // Deleting specific user
      // Add a new document in collection "cities"
      deleteDoc(doc(db, "users", payload));
      // set(ref(db, 'users/' + payload), {})
      commit('SET_MAIN_LOADING', false);
    }, 
    // Get Registered User Information
    GetUserRegistrationData({commit}, payload) {
      // Create a reference with an initial file path and name
      const storage = getStorage(); 
      // Getting the Registered User by ID
      let userData;
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      //const dbRef = ref(getDatabase());
      // getting the current user from the server
      // ${userID}
      commit('SET_MAIN_LOADING', true);
      getDoc(doc(db, "users", payload)).then((snapshot) => {
      //get(child(dbRef, 'users/' + payload)).then((snapshot) => {
        userData = snapshot.data();

        // If userData is not Valid
        if (!userData || userData == null) {
          // let dialogText = {
          //   active: true,
          //   heading: "Can't find the User",
          //   description: "Please try scanning the QR again"
          // }
          // dispatch('setDialog', dialogText);
        }else{
          // Getting the QRImage
          getDownloadURL(Iref(storage, 'users/' + userData.displayName + '.gif')).then((url) => {
            userData.qrimage = url
          }).catch((err) => {
            if (err){
              userData.qrimage = "https://storage.absaco.com/" + userData.displayName + ".gif"
            }
          }) 
          commit('SET_REGISTERED_USER_INFORMATION', userData);
          commit('SET_MAIN_LOADING', false);
        }

      })

      //commit('SET_MAIN_LOADING', false);
      
    },
    // Get All Registered User Information
    GetAllUserRegistrationData({commit}) {
      // Getting the Registered User by ID
      let AlluserData = [];
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      //const dbRef = ref(getDatabase());
      // const docRef = doc(db, "users", "BasicSetup");
      // const docSnap = getDoc(docRef);
      commit('SET_MAIN_LOADING', true);
      // ONLY IF User Logged IN
      // if (getters.user != null) {
        getDocs(collection(db, "users")).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            AlluserData.push(doc.data())
            commit('SET_ALL_REGISTERED_USER_INFORMATION', AlluserData);
            commit('SET_MAIN_LOADING', false);
          }); 
        });
      // }
      // commit('SET_MAIN_LOADING', false);
      
      // getDocs(query(collection(db, "users"))).then(docSnap => {
      //   if (docSnap) {
      //     console.log("Document data:", docSnap);
      //   } else {
      //     console.log("No such document!");
      //   }
      // })
      
      // get(child(dbRef, 'users/')).then((snapshot) => {
      //   AlluserData = snapshot.val();
      //   //      Sanitizing Area
      //   let AllRegUserData = [];
      //   for (var i in AlluserData) {
      //     let data = AlluserData[i];
      //       for(var p in data){
      //         if (!data[p]){
      //           data[p] = "-"
      //           //console.log('section',  p)
      //         }
              
      //       }
      //     // console.log('User Information Splice',  data)
      //     AllRegUserData.push(data);
      //   }
        //console.log('User Information',  JSON.stringify(AllRegUserData))

      // })
      
    },
    SetFireStoreSampleData({getters}) {
      // Initialize Cloud Firestore and get a reference to the service
      const db = getFirestore(app);
      //Getting all the selected units and iterating each one of them to post it online
      let users = getters.GetAllRegisteredUsers;
      for (let index = 0; index < users.length; index++) {
        let element = users[index];
        console.log(element);
        console.log(element["displayName"]);

        setDoc(doc(db, "users", element["displayName"]), element);// Setting the data
      }
        
      // Getting the Registered User by ID
      let data = {name:"trape", description:"Grade exc"}
      

      // Add a new document in collection "cities"
      setDoc(doc(db, "users", "new-city-id"), data);
    },
    SetFireStoreQRImages({getters}) {
      // Initialize Cloud Firestore / Storage and get a reference to the service
      const storage = getStorage();
      
      //const db = getFirestore(app);
      //Getting all the selected units and iterating each one of them to post it online
      let users = getters.GetAllRegisteredUsers;
      for (let index = 0; index < users.length; index++) {
        let element = users[index];
        console.log(element);
        console.log(element["displayName"]);
        const qrimagesrc = element.qrImage;

        // Setting the Storage Reference
        const storageRef = Iref(storage, 'users/' + element.displayName + '.gif');

        uploadString(storageRef, qrimagesrc, 'data_url').then((snapshot) => {
          console.log('Uploaded a blob or file!', snapshot);
          }).then(() => {
            console.log("Sending File", element.displayName)
            // Sending QR Image to FileVault - PHP Server
            let data = new FormData();
            // Sending DisplayName to create pretty URL with like absaqr.radean.xyz/{displayName}.gif
            data.append('displayName', '"' + element.displayName + '"');
            // Sending image to upload to PHP Server
            data.append('qrImageFile', qrimagesrc);
            //console.log("Image Data: ", data);
            axios({
              method: 'post',
              url: 'http://storage.absaco.com',
              headers: {
                // "Access-Control-Allow-Origin" : "*",
                // 'Access-Control-Allow-Methods': 'POST',
                'Content-Type': 'multipart/form-data',
              },
              data: data
            }).then((response) => {
              console.log(JSON.stringify(response.data))
            })
          }).catch(
            error => {
              console.log(error);
          })
      }
    }
  },
  getters: {
    // Application Details
    appinfo (state){
      return state.appinfo
    },
    userinfo (state) {
      return state.userinfo
    },
    appAuth (state){
      return state.appAuth
    },
    user (state){
      return state.user
    },
    containerInfo (state) {
      return state.containerinfo
    },
    loadingState (state) {
      return state.loadingState
    },
    dialogText (state) {
      return state.DialogText
    },
    snackBarText (state) {
      return state.SnackBarText
    },
    SearchedUser (state) {
      return state.RegisteredUser
    },
    GetAllRegisteredUsers(state) {
      return state.AllRegisteredUser
    },
    Redirector (state) {
      return state.redirect
    },
    avatarText(state) {
      if (state.user) {
        let text = String(state.user.email)
        let out = text.substring(0, text.lastIndexOf("@"))
        return out[0]
      }
      return null;
    }
  }
});